import get from 'lodash/get';
import includes from 'lodash/includes';
import {
  selectBranchUrl,
  selectIsDiscord,
  selectIsMobile,
} from 'src/common/selectors/app';
import {
  selectDidShowDownloadAppDialog,
  selectIsDownloadAppDialogOpen,
} from 'src/common/selectors/dialog';
import {
  WEB_FIRST_PLAY_INSTALL_APP_DIALOG,
  WEB_OPEN_INSTALL_APP_DIALOG,
  WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS,
} from '../../../constants/experiments/dialog';

export default function canShowInstallAppDialog(state) {
  const isMobile = selectIsMobile(state);
  const branchUrl = selectBranchUrl(state);
  const isDiscord = selectIsDiscord(state);

  if (!isMobile || !branchUrl || isDiscord) {
    return false;
  }

  const hideInstallAppDialogForGuideIds = get(state, [
    'config',
    'autoupdate',
    WEB_OPEN_INSTALL_APP_DIALOG_DISABLED_GUIDEIDS,
  ]);
  const guideId = get(state, ['player', 'nowplaying', 'primaryGuideId']);
  const shouldHideInstallAppDialog = hideInstallAppDialogForGuideIds
    ? includes(hideInstallAppDialogForGuideIds.split(','), guideId)
    : false;
  const openInstallAppDialog = get(state, [
    'config',
    'autoupdate',
    WEB_OPEN_INSTALL_APP_DIALOG,
  ]);
  const openInstallDialogOnlyOnFirstPlay = get(state, [
    'config',
    'autoupdate',
    WEB_FIRST_PLAY_INSTALL_APP_DIALOG,
  ]);
  const isDownloadAppDialogOpen = selectIsDownloadAppDialogOpen(state);
  const didShowDownloadAppDialog = selectDidShowDownloadAppDialog(state);

  return !!(
    !shouldHideInstallAppDialog &&
    !isDownloadAppDialogOpen &&
    (openInstallAppDialog ||
      (openInstallDialogOnlyOnFirstPlay && !didShowDownloadAppDialog))
  );
}
