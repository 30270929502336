export const TRACK_GO_PREMIUM_VIEW = 'TRACK_GO_PREMIUM_VIEW';
export const TRACK_SIGN_UP_VIEW = 'TRACK_SIGN_UP_VIEW';
export const TRACK_SUBSCRIPTION_VIEW = 'TRACK_SUBSCRIPTION_VIEW';
export const TRACK_OPT_IN_CONFIRMATION_VIEW = 'TRACK_OPT_IN_CONFIRMATION_VIEW';

export function trackGoPremiumLandingPage() {
  return {
    type: TRACK_GO_PREMIUM_VIEW,
  };
}

export function trackSignUpPage() {
  return {
    type: TRACK_SIGN_UP_VIEW,
  };
}

export function trackSubscriptionPage() {
  return {
    type: TRACK_SUBSCRIPTION_VIEW,
  };
}

export function trackOptInConfirmationPage(purchasePrice) {
  return {
    type: TRACK_OPT_IN_CONFIRMATION_VIEW,
    meta: {
      purchasePrice,
    },
  };
}
