import get from 'lodash/get';
import mapProfileDetails from './mapProfileDetails';

export default function mapResponse(data) {
  const userProfile = get(data, ['items', 0, 'children', 0]);
  const mappedUserProfile = mapProfileDetails(userProfile);
  const followAction = userProfile.actions.follow;

  return {
    ...mappedUserProfile,
    containerItems: data.items.slice(1),
    followerCount: followAction.followerCount,
    followerText: followAction.followText,
  };
}
