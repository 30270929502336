import get from 'lodash/get';
import { parse } from 'qs';
import { PROFILE_QUERY_PARAM } from '../constants/queryParams';
import { fetchProfileEffect } from './profile';

export const FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW =
  'FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW';
export const RESET_SUBSCRIPTION_FLOW = 'RESET_SUBSCRIPTION_FLOW';

export function fetchProfile(guideId) {
  return fetchProfileEffect({ guideId }, FETCH_PROFILE_FOR_SUBSCRIPTION_FLOW);
}

export function clearProfile() {
  return { type: RESET_SUBSCRIPTION_FLOW };
}

// used in subscription flow to display station-specific artwork and title
// forceFetch used in order to update the guideItem in cases where user was not
// previous subscribed and then became subscribed (to udpate the behaviors and actions
// coming form the api response on the guideItem for premium items)
// (see files: UpsellCard)
export const fetchProfileIfInQueryByLocation =
  (location, forceFetch) => (_, getState) => {
    const query = parse(get(location, 'search', ''));
    const guideId = get(query, PROFILE_QUERY_PARAM);
    if (guideId) {
      const subscriptionFlowGuideId = get(
        getState(),
        'subscriptionFlow.profile.item.guideItem.guideId',
      );
      if (
        forceFetch ||
        !subscriptionFlowGuideId ||
        guideId !== subscriptionFlowGuideId
      ) {
        return fetchProfile(guideId);
      }
      return null;
    }

    return clearProfile();
  };
