import get from 'lodash/get';
import { DISABLE_RECURLY_PRODUCT_CACHE } from '../../constants/experiments/config';

export default function getDisabledCacheConfig(state) {
  return get(
    state,
    ['config', 'autoupdate', DISABLE_RECURLY_PRODUCT_CACHE],
    false,
  );
}
