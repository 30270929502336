import get from 'lodash/get';
import subscribe from '../constants/analytics/categoryActionLabel/subscribe';
import unsubscribe from '../constants/analytics/categoryActionLabel/unsubscribe';
import { statuses as couponStatuses } from '../constants/subscription/coupon';
import {
  selectCouponCacheConfig,
  selectRecurlyCouponPrefixes,
} from '../selectors/config';
import {
  selectSubscriptionProviderName,
  selectUserAccountId,
  selectUserDetails,
} from '../selectors/me';
import {
  selectCoupon,
  selectCurrentSubProductPriceId,
  selectCurrentSubProductSku,
  selectCustomerAddress,
  selectSubProviderSubId,
} from '../selectors/subscription';
import fetchProducts from '../utils/connectors/fetchProducts';
import { mustBeLoggedIn } from '../utils/form/paymentValidation';
import generateOriginLabelFromQuery from '../utils/generateOriginLabelFromQuery';
import { providers } from '../utils/subscription/getSubscriptionProvider';
import isCouponValidForSku from '../utils/subscription/isCouponValidForSku';
import shouldUseStripe from '../utils/subscription/shouldUseStripe';
import { currentSubscriptionStatus } from '../utils/subscription/subscriptionStatus';
import { setCountryAndState, validateLocation } from './location';
import {
  logClientError,
  logClientInfo,
  logUnsubscribeActivity,
} from './logging';
import getCountryCode from './utils/getCountryConfig';
import getDisabledCacheConfig from './utils/getDisabledCacheConfig';
import unWrapSubscriptionError from './utils/unWrapSubscriptionError';

export const GET_SIGNATURE = 'GET_SIGNATURE';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const RENEW_SUBSCRIPTION = 'RENEW_SUBSCRIPTION';
export const GET_PAYMENT_INFORMATION = 'GET_PAYMENT_INFORMATION';
export const UPDATE_PAYMENT_INFORMATION = 'UPDATE_PAYMENT_INFORMATION';
export const FETCH_COUPON = 'FETCH_COUPON';
export const CREATE_SUBSCRIPTION_USER = 'CREATE_SUBSCRIPTION_USER';
export const CREATE_SETUP_INTENT = 'CREATE_SETUP_INTENT';
export const CLEAR_SUBSCRIPTION_CURRENT_STATE =
  'CLEAR_SUBSCRIPTION_CURRENT_STATE';
export const POST_RECEIPT_TO_REV_CAT = 'POST_RECEIPT_TO_REV_CAT';
export const LINK_EXISTING_SUBSCRIPTION = 'LINK_EXISTING_SUBSCRIPTION';
export const REPORT_ROKT_CONVERSION = 'REPORT_ROKT_CONVERSION';
export const UPDATE_LOCAL_CUSTOMER_ADDRESS = 'UPDATE_LOCAL_CUSTOMER_ADDRESS';
export const CLEAR_SETUP_INTENT_STATE = 'CLEAR_SETUP_INTENT_STATE';

const transform = ({ data } = {}) => ({ content: data });

export function clearSubscriptionCurrentState() {
  return {
    type: CLEAR_SUBSCRIPTION_CURRENT_STATE,
  };
}

function getSubscriptionEffect(searchByAccountId = false) {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const existingProviderName = selectSubscriptionProviderName(state);

    if (
      isStripe &&
      !searchByAccountId &&
      existingProviderName !== providers.stripe
    ) {
      return null;
    }

    return dispatch({
      type: GET_SUBSCRIPTION,
      api: {
        endpoint: ['subscription', 'getSubscription'],
        transform,
        args: [
          {
            countryCode: getCountryCode(state),
            noCache: getDisabledCacheConfig(state),
            noCouponCache: selectCouponCacheConfig(state),
            isStripe,
            searchByAccountId,
          },
        ],
      },
    });
  };
}

function cancelSubscriptionEffect() {
  return (dispatch, getState) => {
    const isStripe = shouldUseStripe(getState());
    return dispatch({
      type: CANCEL_SUBSCRIPTION,
      api: {
        endpoint: ['subscription', 'cancelSubscription'],
        args: [{ isStripe }],
      },
    });
  };
}

export function createTuneinSubscription(
  currency,
  token,
  productSku,
  source,
  couponCode,
  priceId = '',
) {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const countryCode = getCountryCode(state);
    const address = selectCustomerAddress(state);

    return dispatch({
      type: CREATE_SUBSCRIPTION,
      api: {
        endpoint: ['subscription', 'createSubscription'],
        transform,
        args: [
          {
            currency,
            token,
            source,
            productSku,
            couponCode,
            priceId,
            isStripe,
            countryCode,
          },
          { address },
        ],
      },
      meta: {
        contentId: 'tuneinSubscription',
      },
    });
  };
}

export function renewTuneinSubscription(
  currency,
  token,
  productSku,
  source,
  couponCode,
  priceId,
) {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const address = selectCustomerAddress(state);

    return dispatch({
      type: RENEW_SUBSCRIPTION,
      api: {
        endpoint: ['subscription', 'renewSubscription'],
        transform,
        args: [
          {
            currency,
            token,
            productSku,
            source,
            couponCode,
            priceId,
            isStripe,
          },
          { address },
        ],
      },
      meta: {
        contentId: 'tuneinSubscription',
      },
    });
  };
}

export function getPaymentInformationEffect() {
  return (dispatch, getState) => {
    const isStripe = shouldUseStripe(getState());
    return dispatch({
      type: GET_PAYMENT_INFORMATION,
      api: {
        endpoint: ['subscription', 'getPaymentInformation'],
        transform,
        args: [{ isStripe }],
      },
      meta: {
        contentId: 'tuneinSubscription',
      },
    });
  };
}

export function updatePaymentInformationEffect(token) {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const address = selectCustomerAddress(state);

    return dispatch({
      type: UPDATE_PAYMENT_INFORMATION,
      api: {
        retries: 2,
        endpoint: ['subscription', 'updatePaymentInformation'],
        transform,
        args: [{ token, isStripe }, { address }],
      },
      meta: {
        contentId: 'tuneinSubscription',
      },
    });
  };
}

export function createSubscriptionUser(stripeIntentId) {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);

    return dispatch({
      type: CREATE_SUBSCRIPTION_USER,
      api: {
        endpoint: ['subscription', 'createCustomer'],
        transform,
        args: [
          {
            noCache: getDisabledCacheConfig(state),
            noCouponCache: selectCouponCacheConfig(state),
            isStripe,
            stripeIntentId,
          },
        ],
      },
      meta: {
        contentId: 'subProviderInfo',
      },
    });
  };
}

export function createSetupIntent() {
  return (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);

    return dispatch({
      type: CREATE_SETUP_INTENT,
      api: {
        retries: 2,
        endpoint: ['subscription', 'createIntent'],
        transform,
        args: [
          {
            noCache: getDisabledCacheConfig(state),
            noCouponCache: selectCouponCacheConfig(state),
            isStripe,
          },
        ],
      },
      meta: {
        contentId: 'subProviderInfo',
      },
    });
  };
}

export function createAccount(
  currency,
  token,
  upsellReferrer,
  productSku,
  couponCode,
  priceId = '',
) {
  return (dispatch, getState) => {
    const { id } = selectUserDetails(getState()) || {};
    if (!id) {
      throw new Error(mustBeLoggedIn);
    }
    return dispatch(
      createTuneinSubscription(
        currency,
        token,
        productSku,
        upsellReferrer,
        couponCode,
        priceId,
      ),
    ).catch(unWrapSubscriptionError);
  };
}

export function postReceiptToRevCat() {
  return async (dispatch, getState) => {
    const state = getState();
    const subscriptionId = selectSubProviderSubId(state);
    const tuneInUserGuideId = selectUserAccountId(state);

    const action = {
      type: POST_RECEIPT_TO_REV_CAT,
      api: {
        retries: 2,
        endpoint: ['subscription', 'postReceiptToRevCat'],
        args: [subscriptionId, tuneInUserGuideId],
      },
      meta: {
        contentId: 'revCatStatus',
      },
    };

    try {
      const result = await dispatch(action);
      dispatch(
        logClientInfo({
          message: 'postReceiptToRevCat success',
          context: {
            guideId: tuneInUserGuideId,
          },
        }),
      );
      return result;
    } catch (error) {
      dispatch(
        logClientError({
          message: 'postReceiptToRevCat failure',
          context: {
            error,
            guideId: tuneInUserGuideId,
          },
        }),
      );

      throw error;
    }
  };
}

export function linkExistingSubscription(location) {
  return async (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const coupon = selectCoupon(state);
    const sku = selectCurrentSubProductSku(state);
    const priceId = selectCurrentSubProductPriceId(state);
    const tuneInUserGuideId = selectUserAccountId(state);

    const querySource =
      generateOriginLabelFromQuery(location) || subscribe.labels.payment;
    const hasValidCoupon = isCouponValidForSku(state, location, sku);
    const couponCode = (hasValidCoupon && coupon?.code) || null;

    // retries happen on the server for linkSubscription
    const action = {
      type: LINK_EXISTING_SUBSCRIPTION,
      api: {
        endpoint: ['subscription', 'linkExistingSubscription'],
        args: [
          {
            couponCode,
            source: querySource,
            priceId,
            productSku: sku,
            isStripe,
          },
        ],
      },
      meta: {
        contentId: 'linkExistingSubscriptionStatus',
      },
    };

    try {
      const response = await dispatch(action);
      dispatch(
        logClientInfo({
          message: 'linkExistingSubscription success',
          context: {
            guideId: tuneInUserGuideId,
          },
        }),
      );
      return response;
    } catch (error) {
      dispatch(
        logClientError({
          message: 'linkExistingSubscription failure',
          context: {
            error,
            guideId: tuneInUserGuideId,
          },
        }),
      );

      throw error;
    }
  };
}

export function renewSubscription(
  currency,
  token,
  productSku,
  upsellReferrer,
  couponCode,
  priceId = '',
) {
  return (dispatch, getState) => {
    const { id } = get(getState(), 'me.details', {});
    if (!id) {
      throw new Error(mustBeLoggedIn);
    }
    return dispatch(
      renewTuneinSubscription(
        currency,
        token,
        productSku,
        upsellReferrer,
        couponCode,
        priceId,
      ),
    ).catch(unWrapSubscriptionError);
  };
}

export function getSubscription(searchByAccountId = false) {
  return (dispatch) => dispatch(getSubscriptionEffect(searchByAccountId));
}

export function getPaymentInformation() {
  return (dispatch, getState) => {
    const { id } = get(getState(), 'me.details', {});
    if (!id) {
      throw new Error(mustBeLoggedIn);
    }

    return dispatch(getPaymentInformationEffect());
  };
}

export function updatePaymentInformation(token) {
  return (dispatch, getState) => {
    const { id } = get(getState(), 'me.details', {});
    if (!id) {
      throw new Error(mustBeLoggedIn);
    }
    return dispatch(updatePaymentInformationEffect(token)).catch(
      unWrapSubscriptionError,
    );
  };
}

export function cancelSubscription(location) {
  return async (dispatch, getState) => {
    const { current } = getState().subscription;

    if (current && current.status === currentSubscriptionStatus.canceled) {
      return null;
    }

    const label =
      current && current.billingPeriod
        ? unsubscribe.labels[current.billingPeriod.toLowerCase()]
        : '';
    await dispatch(cancelSubscriptionEffect());
    dispatch(fetchProducts(location, true));
    return dispatch(logUnsubscribeActivity(unsubscribe.actions.confirm, label));
  };
}

export function updateLocationPaymentInformation(updatedZip, country, state) {
  return async (dispatch, getState) => {
    const previousZip = get(getState(), 'subscription.paymentInfo.postalCode');

    const shouldLookUpZip = !get(
      getState(),
      'subscription.paymentInfo.enableManualSelection',
    );
    if (shouldLookUpZip || previousZip !== updatedZip) {
      await dispatch(validateLocation(updatedZip));
    } else {
      await dispatch(setCountryAndState({ country, state }));
    }
  };
}

export function fetchCoupon(
  couponCode,
  status,
  isProductDefaultCoupon = false,
) {
  return (dispatch, getState) => {
    const state = getState();
    const recurlyCouponPrefixes = selectRecurlyCouponPrefixes(state);
    const shouldUseRecurlyBasedOnPrefix = recurlyCouponPrefixes?.some(
      (prefix) => couponCode?.startsWith(prefix),
    );
    const isStripe = !shouldUseRecurlyBasedOnPrefix && shouldUseStripe(state);
    return dispatch({
      type: FETCH_COUPON,
      api: {
        endpoint: ['subscription', 'lookupCoupon'],
        args: [{ couponCode, status, isStripe }],
        registerAppError: false,
      },
      meta: {
        isProductDefaultCoupon,
      },
    });
  };
}

export function validateActiveCoupon(couponCode) {
  return fetchCoupon(couponCode, couponStatuses.redeemable);
}

export function fetchActiveCouponIfNeeded(couponCode) {
  return (dispatch, getState) => {
    const couponCodeInStore = get(getState(), 'subscription.coupon.code');

    if (couponCode !== couponCodeInStore) {
      return dispatch(fetchCoupon(couponCode, couponStatuses.redeemable));
    }

    // calling then on this function so must resolve the promise
    return Promise.resolve();
  };
}

export function reportRoktConversion(roktId) {
  return {
    type: REPORT_ROKT_CONVERSION,
    api: {
      endpoint: ['analytics', 'reportRoktConversion'],
      args: [roktId],
    },
  };
}

export function updateLocalCustomerAddress(address) {
  return {
    type: UPDATE_LOCAL_CUSTOMER_ADDRESS,
    address,
  };
}

export function clearSetupIntentState() {
  return {
    type: CLEAR_SETUP_INTENT_STATE,
  };
}

/*
  see connectors folder for connector action creators:

  fetchSubscriptionIfNeeded
  fetchCouponForRoute
*/
