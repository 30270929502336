import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';

export const customFavoriteFoldersContainerType = 'FavoriteFolders';
export const baseFolderIdKey = 'properties.favoriteFolder.id';
export const folderIdKey = 'folderId';

/*
  the favorites response is unique.  we need perform do some
  transformations to make it usable in our flow.
  it's shape looks like this:
  [
    <header (we don't use this)>,
    (<container with destination info pointing to a user's "custom" folders, sometimes not present>)
    ...<"default" favorite folders created by platform (eg Artists, Users)>
    <some other item (we don't use this either)>
  ]

  we create a table (favoriteFoldersIndex) for easy lookup
  and a an array of ids ((custom|default)FavoriteFoldersOrdered)
  that dictates the order in which we display the folders.
*/

export default function mapUserFavorites(user) {
  // we remove last one to hide old Custom URL functionality
  const containerItems = user.containerItems.slice(0, -1);

  const customFavoriteFolders =
    containerItems
      .find(
        (item) => item?.containerType === customFavoriteFoldersContainerType,
      )
      ?.children?.map((folder) => ({
        ...folder,
        userId: user.id,
        isCustom: true,
        [folderIdKey]: `${get(folder, baseFolderIdKey)}`,
      })) || [];

  const defaultFavoriteFolders = containerItems
    .filter(
      (item) => item?.containerType !== customFavoriteFoldersContainerType,
    )
    .map((folder, idx) => ({
      ...folder,
      userId: user.id,
      isCustom: false,
      [folderIdKey]: folder.guideId || `meta-${idx}`,
    }));

  const customFavoriteFoldersOrdered = map(customFavoriteFolders, folderIdKey);
  const defaultFavoriteFoldersOrdered = map(
    defaultFavoriteFolders,
    folderIdKey,
  );
  const customFavoriteFoldersIndexed = keyBy(
    customFavoriteFolders,
    folderIdKey,
  );
  const defaultFavoriteFoldersIndexed = keyBy(
    defaultFavoriteFolders,
    folderIdKey,
  );

  return {
    moreItemsIndexName: 'defaultFavoriteFoldersIndexed',
    customFavoriteFoldersOrdered,
    defaultFavoriteFoldersOrdered,
    customFavoriteFoldersIndexed,
    defaultFavoriteFoldersIndexed,
  };
}
