export const GET_LOCATION = 'GET_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATION_OPTIONS = 'SET_LOCATION_OPTIONS';
export const LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND';

const locationApi = '/api/v1/location';

import {
  chooseLocationText,
  invalidPostalCodeErrorText,
  manualStateAndCountryText,
} from 'src/common/utils/form/paymentValidation';

function transformLocationOptions(data) {
  return {
    countryName: data?.country,
    stateName: data?.administrativeLevels?.level1long,
    country: data?.countryCode,
    state: data?.administrativeLevels?.level1short,
  };
}

function transform(data) {
  return {
    country: data?.countryCode,
    state: data?.administrativeLevels?.level1short,
  };
}

function getLocation(postalCode) {
  return {
    type: GET_LOCATION,
    postalCode,
  };
}

function setLocationOptions(locations) {
  return {
    type: SET_LOCATION_OPTIONS,
    locations: locations.map((l) => transformLocationOptions(l)),
  };
}

function throwLocationError(message = invalidPostalCodeErrorText) {
  throw new Error(message);
}

export function setCountryAndState({ country, state }) {
  return {
    type: SET_LOCATION,
    country,
    state,
  };
}

export function selectLocationOption(index) {
  return (dispatch, getState) => {
    const { subscription } = getState();
    const { country, state } = subscription.paymentInfo.options[index];

    dispatch(setCountryAndState({ country, state }));
  };
}

function locationNotFound() {
  return {
    type: LOCATION_NOT_FOUND,
  };
}

export function validateLocation(postalCode) {
  return (dispatch, getState) => {
    const { subscription } = getState();
    const { paymentInfo } = subscription;
    const previousPostalCode = paymentInfo && paymentInfo.postalCode;

    if (postalCode === previousPostalCode && paymentInfo.country) {
      return Promise.resolve();
    }

    dispatch(getLocation(postalCode));

    return fetch(`${locationApi}?address=${postalCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .catch(() =>
        throwLocationError('Something went wrong, please try again!'),
      )
      .then((response) => response.json())
      .then((data) => {
        const locations = data;

        if (locations.length === 0) {
          dispatch(locationNotFound());
          throwLocationError(manualStateAndCountryText);
        } else if (locations.length > 1) {
          dispatch(setLocationOptions(locations));
          throwLocationError(chooseLocationText);
        }
        return dispatch(setCountryAndState(transform(locations[0])));
      });
  };
}
