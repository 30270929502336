import { isUser } from '@tunein/web-common';
import { addReportingMetadata } from './utils/addReportingMetadata';

export const FETCH_MORE_ITEMS = 'FETCH_MORE_ITEMS';

const requestTypeEndpointMap = {
  Browse: 'browse.getItems',
  Profile: 'profile.getV3',
  Search: 'profile.search',
};

function apiResponseTransform(response, containerItem = {}) {
  const { items: children, metadata } = response.data;
  const { seoInfo = {} } = metadata?.properties || {};

  addReportingMetadata(seoInfo)({ ...containerItem, children });

  return {
    auth: response.auth,
    content: {
      guideItems: children,
      containerNavigation: response.data.paging,
    },
  };
}

export function fetchMoreItems(requestParams) {
  const {
    selectedContentId,
    containerNavigation,
    containerIndex,
    offset,
    limit,
    containerItem,
  } = requestParams;
  const { destinationInfo } = containerNavigation;

  const endpoint =
    destinationInfo.id &&
    (isUser(destinationInfo.id) || destinationInfo.id === 'me') // HACK FIXME
      ? 'profile.userById'
      : requestTypeEndpointMap[destinationInfo.requestType];

  const apiParams = {
    ...destinationInfo.attributes,
    // needed due to inconsistencies with how API returns itemToken property
    itemToken:
      containerNavigation.itemToken || containerNavigation.context.itemToken,
    offset,
    limit,
  };

  return {
    type: FETCH_MORE_ITEMS,
    api: {
      endpoint,
      args: [destinationInfo.id, apiParams],
      transform: (response) => apiResponseTransform(response, containerItem),
    },
    meta: {
      contentId: selectedContentId,
      containerIndex,
    },
  };
}
