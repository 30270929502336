import transform from 'lodash/transform';
import qs from 'qs';
import { isProdEnv } from 'src/common/utils/environment';
import isServer from 'src/common/utils/isServer';
import {
  statuses as couponStatuses,
  discountTypes,
} from '../constants/subscription/coupon';
import { selectCouponCacheConfig } from '../selectors/config';
import billingFrequency from '../utils/subscription/billingPeriod';
import formatCents from '../utils/subscription/formatCents';
import shouldUseStripe from '../utils/subscription/shouldUseStripe';
import { logClientError, logClientInfo } from './logging';
import { fetchCoupon } from './subscription';
import getCountryCode from './utils/getCountryConfig';
import getDisabledCacheConfig from './utils/getDisabledCacheConfig';

export const GET_PRODUCTS_BY_SKUS = 'GET_PRODUCTS_BY_SKUS';
export const PRODUCTS_TRANSFORM = 'PRODUCTS_TRANSFORM';
export const UPDATE_SKU_RENEW_STATUSES = 'UPDATE_SKU_RENEW_STATUSES';
const skuFetchError = 'SKU FETCH ERROR';

const VERY_BAD_COUPON = '0oA7DER7';

// Note: Added to investigate https://tunein.atlassian.net/browse/TUNE-17132 using the logs in NR.
function logApplyCouponDiscountInfo(logger, message, data) {
  if (!isProdEnv() || data?.couponData?.code !== VERY_BAD_COUPON) {
    return;
  }

  const { dispatch, loggerInfo } = logger;
  if (dispatch) {
    return dispatch(
      logClientInfo({
        message,
        context: data,
      }),
    );
  }

  if (isServer && loggerInfo) {
    loggerInfo({
      message,
      meta: data,
    });
  }
}

/**
 * applyCouponDiscount - creates formattedDiscountedPrice and discountedCurrencies properties for a
 * product, based on the coupon data available.
 *
 * @param  {object} product    the product metadata
 * @param  {object} couponData the coupon metadata
 * @return {type}            the old product definition, or new one with discount metadata.
 */
export function applyCouponDiscount(
  product,
  couponData,
  isUsingDefaultCoupon = false,
  logger,
) {
  if (
    couponData.durationInMonths &&
    product.billingPeriod === billingFrequency.yearly
  ) {
    logApplyCouponDiscountInfo(
      logger,
      'applyCouponDiscount.canceledDueToDurationInMonths',
      {
        couponData,
        product,
      },
    );
    return product;
  }

  // Only apply a discount to a product if either:
  // 1. the coupon itself applies to all metadata.
  // 2. the product is included in the plans that the coupon applies to.
  if (
    couponData.appliesToAllPlans ||
    couponData.plans.includes(product.sku) ||
    couponData.plans.includes(product?.stripeProduct?.id)
  ) {
    const newProduct = {
      ...product,
      isUsingDefaultCoupon,
    };

    // Calculate discounted prices based on percentage.
    if (couponData.type === discountTypes.percent) {
      const percentage = 1 - couponData.percentage / 100;

      if (percentage === 0) {
        newProduct.isFree = true;
      }

      newProduct.formattedDiscountedPrice = formatCents(
        Math.ceil(newProduct.price * percentage),
        newProduct.currencyCode,
      );

      newProduct.discountedCurrencies = transform(
        newProduct.currencies,
        (result, value, key) => {
          // eslint-disable-next-line no-param-reassign
          result[key] = Math.ceil(value * percentage);
        },
        {},
      );
    } else if (couponData.type === discountTypes.dollars) {
      // Calculate discounted prices based on the amount to subtract.
      const discountedCentsForCurrency =
        couponData.centsCurrencyMap[newProduct.currencyCode];
      const newPrice = newProduct.price - discountedCentsForCurrency;

      if (newPrice === 0) {
        newProduct.isFree = true;
      }

      newProduct.formattedDiscountedPrice = discountedCentsForCurrency
        ? formatCents(newPrice, newProduct.currencyCode)
        : newProduct.formattedPrice;

      newProduct.discountedCurrencies = transform(
        newProduct.currencies,
        (result, value, key) => {
          const discountedCents = couponData.centsCurrencyMap[key];

          // eslint-disable-next-line no-param-reassign
          result[key] = discountedCents ? value - discountedCents : value;
        },
        {},
      );
    } else if (couponData.type === discountTypes.freeTrial) {
      newProduct.discountFreeTrialLength = couponData.freeTrialAmount;
      newProduct.discountFreeTrialUnit = couponData.freeTrialUnit;
    }

    newProduct.discountDurationType = couponData.durationType;
    if (couponData.durationInMonths) {
      newProduct.discountDurationInMonths = couponData.durationInMonths;
    }

    logApplyCouponDiscountInfo(logger, 'applyCouponDiscount.done', {
      couponData,
      product,
      newProduct,
    });

    return newProduct;
  }

  logApplyCouponDiscountInfo(
    logger,
    'applyCouponDiscount.canceledDueToCouponDoesNotApplyToAnyProduct',
    {
      couponData,
      product,
    },
  );

  return product;
}

async function fetchAndApplyDefaultCouponDiscount(product, dispatch) {
  if (product.defaultCouponCode) {
    try {
      const couponResponse = await dispatch(
        fetchCoupon(product.defaultCouponCode, couponStatuses.redeemable, true),
      );
      const withCoupon = applyCouponDiscount(
        product,
        couponResponse?.value?.data,
        true,
        { dispatch },
      );
      return withCoupon;
    } catch (e) {
      // add logging here in the future
      return product;
    }
  }
  return product;
}

function transformProducts(data, skuMeta) {
  return async (dispatch, getState) => {
    const couponData = getState()?.subscription?.coupon;
    const dataArray = Array.isArray(data) ? data : [data];

    // this should take priority over default coupons
    if (couponData && couponData.type) {
      return dispatch({
        type: PRODUCTS_TRANSFORM,
        meta: {
          skuMeta,
        },
        content: dataArray.map((product) =>
          applyCouponDiscount(
            product,
            couponData,
            couponData.isProductDefaultCoupon,
            { dispatch },
          ),
        ),
      });
    }

    // if there are default coupons they will be fetch and applied here
    return dispatch({
      type: PRODUCTS_TRANSFORM,
      meta: {
        skuMeta,
      },
      content: await Promise.all(
        data.map((product) =>
          fetchAndApplyDefaultCouponDiscount(product, dispatch),
        ),
      ),
    });
  };
}

function getProductsBySkusEffect(skus, countryCode, skuMeta) {
  return async (dispatch, getState) => {
    const state = getState();
    const isStripe = shouldUseStripe(state);
    const params = {
      skus: qs.stringify({ skus }, { indices: false }),
      countryCode,
      noCache: getDisabledCacheConfig(state),
      noCouponCache: selectCouponCacheConfig(state),
      isStripe,
    };

    return await dispatch({
      type: GET_PRODUCTS_BY_SKUS,
      api: {
        endpoint: ['subscription', 'getProductsBySkus'],
        transform: ({ data }) => ({ content: data }),
        args: [params],
      },
      meta: {
        skuMeta,
        contentId: 'productFetchStatus',
      },
    });
  };
}

/**
 * @param {string[]} skus - array of skus to be updated
 * @param {boolean} isRenew - new isRenew value
 * @returns {object}
 */
export function updateSkuRenewStatuses(skus, isRenew) {
  return {
    type: UPDATE_SKU_RENEW_STATUSES,
    meta: {
      skus,
      isRenew,
    },
  };
}

/**
 * @param {string[]} skus - array of all the skus
 * @param {Object} meta -
  {
    defaultSkus: ['sku1', 'sku2'],
    fromQuery: ['sku3', 'sku4'],
    isRenew: false,
  }
 * @returns {Function}
 */
export function getProductsBySkus(skus, meta) {
  return async (dispatch, getState) => {
    const state = getState();
    const products = skus.map((sku) => state.products[sku]).filter(Boolean);

    if (skus.length === products.length) {
      const skusRequiringUpdate = products
        .filter(({ isRenew }) => isRenew !== meta?.isRenew)
        .map(({ sku }) => sku);
      if (skusRequiringUpdate.length) {
        dispatch(updateSkuRenewStatuses(skusRequiringUpdate, meta?.isRenew));
      }

      return Promise.resolve();
    }

    const countryCode = getCountryCode(state);
    try {
      const productsResponse = await dispatch(
        getProductsBySkusEffect(skus, countryCode, meta),
      );
      return await dispatch(
        transformProducts(productsResponse?.value?.content, meta),
      );
    } catch (error) {
      dispatch(
        logClientError({
          message: `${skuFetchError}: ${error.message}`,
          context: {
            skus,
            error,
          },
        }),
      );

      throw error;
    }
  };
}
