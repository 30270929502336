import { logClientInfo } from 'src/common/actions/logging';
import {
  CAROUSEL_FETCH_SKIP_MSG,
  CAROUSEL_GUIDEID,
} from 'src/common/constants/heroCarousel';
import { selectHeroCarouselItems } from 'src/common/selectors/heroCarousel';
import { selectIsDiscord } from '../selectors/app';
import { getCustomBrowseItemsByGuideId } from './browse';

export const FETCH_CAROUSEL_ITEMS = 'FETCH_CAROUSEL_ITEMS';

export function getCarouselItemsIfNeeded() {
  return (dispatch, getState) => {
    const state = getState();
    const isDiscord = selectIsDiscord(state);

    if (isDiscord) {
      return;
    }

    const carouselItems = selectHeroCarouselItems(state);
    if (!carouselItems.length) {
      return dispatch(
        getCustomBrowseItemsByGuideId(CAROUSEL_GUIDEID, FETCH_CAROUSEL_ITEMS),
      );
    }
    // log cases when not fetched
    dispatch(
      logClientInfo({ message: CAROUSEL_FETCH_SKIP_MSG, context: {} }),
    ).catch(() => {}); // no-op. Catching this in case it errors out for some reason
    return Promise.resolve(); // returning this for testing purposes
  };
}
