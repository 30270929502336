import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { selectIsAuthenticated } from 'src/common/selectors/auth';
import accountCategory from '../constants/analytics/categoryActionLabel/account';
import { viewTypeAttributes } from '../constants/auth/viewTypes';
import {
  ACTION_REQUIRES_AUTH,
  FORGOT_PASSWORD_SUCCESS,
  GENERAL_FAILURE,
  RESET_PASSWORD_SUCCESS,
} from '../constants/localizations/auth';
import { fetchConfig } from './config';
import mapAuthResponse from './data/mapAuthResponse';
import {
  createPendingRegistrationDialogOpenRequest,
  requestUserRegistrationDialogOpen,
} from './dialog';
import { logAccountActivity } from './logging';
import { fetchMe } from './me';

// FSA actions
export const EMAIL_LOGIN = 'EMAIL_LOGIN';
export const OAUTH_LOGIN = 'OAUTH_LOGIN';
export const DESKTOP_GOOGLE_AUTH = 'DESKTOP_GOOGLE_AUTH';
export const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
export const OAUTH_VERIFICATION = 'OAUTH_VERIFICATION';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const UPDATE_GOOGLE_SDK_STATUS = 'UPDATE_GOOGLE_SDK_STATUS';
export const UPDATE_FB_SDK_STATUS = 'UPDATE_FB_SDK_STATUS';

// Non-FSA actions
export const RECEIVE_NEW_AUTH = 'RECEIVE_NEW_AUTH';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';
export const NEEDS_SIGN_IN_DIALOG = 'NEEDS_SIGN_IN_DIALOG';
export const NEEDS_SIGN_UP_DIALOG = 'NEEDS_SIGN_UP_DIALOG';
export const AUTH_DONE = 'AUTH_DONE';
export const ACTION_NEEDING_AUTH = 'ACTION_NEEDING_AUTH';
export const UPDATE_VIEW_TYPE = 'UPDATE_VIEW_TYPE';
export const VERIFY_DEVICE_PIN = 'VERIFY_DEVICE_PIN';
export const GRANT_DEVICE_AUTH = 'GRANT_DEVICE_AUTH';
export const AUTHENTICATION_SUCCESSFUL = 'AUTHENTICATION_SUCCESSFUL';
export const AUTHENTICATION_UNSUCCESSFUL = 'AUTHENTICATION_UNSUCCESSFUL';

const typeToApi = {
  [EMAIL_LOGIN]: 'proxyEmailLogin',
  [OAUTH_LOGIN]: 'proxyOauthLogin',
  [DESKTOP_GOOGLE_AUTH]: 'proxyDesktopGoogleAuthLogin',
  [EMAIL_VERIFICATION]: 'proxyEmailVerification',
  [OAUTH_VERIFICATION]: 'proxyOauthVerification',
  [CREATE_ACCOUNT]: 'proxyCreateAccount',
  [LOGOUT]: 'proxyLogout',
  [FORGOT_PASSWORD]: 'forgotPassword',
  [RESET_PASSWORD]: 'resetPassword',
  [CHANGE_PASSWORD]: 'changePassword',
  [CHANGE_USERNAME]: 'changeUsername',
  [VERIFY_DEVICE_PIN]: 'verifyDevicePin',
  [GRANT_DEVICE_AUTH]: 'grantAuthToDevice',
};

// exported for testing
export const AUTH_ACTIONS_REQUIRING_SIDE_EFFECTS = {
  [EMAIL_LOGIN]: true,
  [OAUTH_LOGIN]: true,
  [CREATE_ACCOUNT]: true,
  [LOGOUT]: true,
  [DESKTOP_GOOGLE_AUTH]: true,
};

function postAuthEffects({ type, meta }) {
  return async (dispatch) => {
    // passing in `true` to bypass typical checks for auth state in fetchMe(),
    // as we want `me` state to be available for processes that kick off post-auth
    const isLogout = type === LOGOUT;
    const fetchMePromise = isLogout ? null : dispatch(fetchMe(true));
    const fetchConfigPromise = dispatch(fetchConfig());
    await Promise.all([fetchMePromise, fetchConfigPromise]);
    if (!isLogout) {
      dispatch({
        type: AUTHENTICATION_SUCCESSFUL,
        meta,
      });
    }
  };
}

function authEffect(action) {
  const { type, args, meta = {}, transform } = action;
  const { labels, actionToLog } = meta;
  const { fail: failLabel, success: successLabel } = accountCategory.labels;
  let authError;
  const authEffectAction = {
    type,
    api: {
      endpoint: ['auth', typeToApi[type]],
      args,
      transform,
    },
    meta,
  };

  return async (dispatch) => {
    try {
      await dispatch(authEffectAction);

      if (AUTH_ACTIONS_REQUIRING_SIDE_EFFECTS.hasOwnProperty(type)) {
        await dispatch(postAuthEffects(action));
      }
    } catch (e) {
      authError = e;
      dispatch({ type: AUTHENTICATION_UNSUCCESSFUL, meta });
    }

    if (actionToLog) {
      const logLabels = Array.isArray(labels) ? labels : [];
      logLabels.push(authError ? failLabel : successLabel);
      await dispatch(logAccountActivity(actionToLog, logLabels));
    }

    if (authError) {
      throw authError;
    }
  };
}

function getOauthEffect(type, providerName, data, params, meta) {
  return authEffect({
    type,
    args: [providerName, data, params],
    meta: {
      ...meta,
      failAuthNotice: GENERAL_FAILURE,
    },
    transform: mapAuthResponse,
  });
}

export function loginWithEmail(loginDetails) {
  const { viewTypeData } = loginDetails;
  return authEffect({
    type: EMAIL_LOGIN,
    args: [loginDetails],
    transform: mapAuthResponse,
    meta: {
      actionToLog: accountCategory.actions.login,
      labels: [accountCategory.labels.email, loginDetails.source],
      viewTypeData,
    },
  });
}

export function verifyWithEmail(verifyDetails) {
  return authEffect({
    type: EMAIL_VERIFICATION,
    args: [verifyDetails],
    meta: {
      actionToLog: accountCategory.actions.verify,
      labels: [accountCategory.labels.email, verifyDetails.source],
      failAuthNotice: GENERAL_FAILURE,
    },
  });
}

export function loginWithGoogleForDesktop(code) {
  return authEffect({
    type: DESKTOP_GOOGLE_AUTH,
    args: [{ code }],
  });
}

export function loginWithOauth(providerName, data, source, params, meta) {
  return getOauthEffect(OAUTH_LOGIN, providerName, data, params, {
    actionToLog: accountCategory.actions.login,
    labels: [providerName, source],
    ...meta,
  });
}

export function verifyWithOauth(providerName, data, source, params) {
  return getOauthEffect(OAUTH_VERIFICATION, providerName, data, params, {
    actionToLog: accountCategory.actions.verify,
    labels: [providerName, source],
  });
}

export function createAccountWithOauth(
  providerName,
  data,
  source,
  params,
  meta,
) {
  return getOauthEffect(OAUTH_LOGIN, providerName, data, params, {
    actionToLog: accountCategory.actions.create,
    labels: [providerName, source],
    source,
    ...meta,
  });
}

export function createAccount(data) {
  const { source, viewTypeData } = data;
  return authEffect({
    type: CREATE_ACCOUNT,
    args: [data],
    transform: mapAuthResponse,
    meta: {
      actionToLog: accountCategory.actions.create,
      labels: [accountCategory.labels.email, source],
      source,
      viewTypeData,
    },
  });
}

export function logout() {
  return authEffect({ type: LOGOUT });
}

// Utilized in the auth middleware
export function unauthenticateUser() {
  return {
    type: UNAUTHENTICATE,
  };
}

// Utilized in the auth middleware
export function loadNewAuth(tokenData) {
  return {
    type: RECEIVE_NEW_AUTH,
    tokenData,
  };
}

// Used for showing the auth dialog with sign in view.
export function needsSignInDialog() {
  return {
    type: NEEDS_SIGN_IN_DIALOG,
  };
}

// Used for showing the auth dialog with sign up view.
export function needsSignUpDialog() {
  return {
    type: NEEDS_SIGN_UP_DIALOG,
  };
}

// Used for hiding the auth dialog.
export function authDone() {
  return {
    type: AUTH_DONE,
  };
}

// Used for showing the auth dialog with sign in view.
export function updateAuthViewType(vt) {
  return {
    type: UPDATE_VIEW_TYPE,
    viewType: vt,
  };
}

// General functions that can be used throughout the app

// Decorates an action creator that is dependent on auth state
// If not authenticated, the ACTION_NEEDING_AUTH is dispatched, containing a callback to dispatch
// the action creator once authenticated
//
// Also passes a boolean to the action creator indicating whether the user was
// logged in or not before this action was called.
//
export function setActionNeedingAuth(actionCreator) {
  return (...args) =>
    (dispatch, getState) => {
      const isAuth = selectIsAuthenticated(getState());
      if (isAuth) {
        return dispatch(actionCreator.call(actionCreator, ...args, isAuth));
      }

      const actionNeedingAuth = () =>
        dispatch(actionCreator.call(actionCreator, ...args, isAuth));

      return dispatch({
        type: ACTION_NEEDING_AUTH,
        actionNeedingAuth,
        failAuthNotice: ACTION_REQUIRES_AUTH,
      });
    };
}

// Decorates a collection of action creators dependent on auth state.
// See #setActionNeedingAuth for more details
export function wrapProtectedActions(...actionCreators) {
  return mapValues(...actionCreators, (actionCreator) =>
    setActionNeedingAuth(actionCreator),
  );
}

export function forgotPassword(email) {
  return authEffect({
    type: FORGOT_PASSWORD,
    actionToLog: accountCategory.actions.submit,
    labels: [accountCategory.labels.forgotPassword],
    args: [email],
    meta: {
      successAuthNotice: FORGOT_PASSWORD_SUCCESS,
    },
  });
}

export function resetPassword(userToken, newPassword) {
  return authEffect({
    type: RESET_PASSWORD,
    args: [userToken, newPassword],
    meta: {
      successAuthNotice: RESET_PASSWORD_SUCCESS,
    },
  });
}

export function changePassword(newPassword) {
  return authEffect({
    type: CHANGE_PASSWORD,
    args: [newPassword],
  });
}

export function changeUsername(newUsername) {
  return authEffect({
    type: CHANGE_USERNAME,
    args: [newUsername],
    meta: newUsername,
  });
}

export function openUserRegistrationDialogWithAuthViewAttribute(
  viewTypeAttribute,
  dialogView,
) {
  return viewTypeAttribute === viewTypeAttributes.fullPage
    ? createPendingRegistrationDialogOpenRequest(dialogView)
    : requestUserRegistrationDialogOpen(dialogView);
}

export function updateGoogleAuthSdkStatus(googleAuthSdkStatus) {
  return {
    type: UPDATE_GOOGLE_SDK_STATUS,
    status: googleAuthSdkStatus,
  };
}

export function updateFbAuthSdkStatus(fbAuthSdkStatus) {
  return {
    type: UPDATE_FB_SDK_STATUS,
    status: fbAuthSdkStatus,
  };
}

// utilized in auth middleware and on server
export function assessAuth(dispatch) {
  return (response) => {
    if (!isEmpty(response?.auth)) {
      if (response.auth.isNotAuthenticated) {
        dispatch(unauthenticateUser());
      }

      if (!isEmpty(response.auth.newTokenData)) {
        dispatch(loadNewAuth(response.auth.newTokenData));
      }
    }

    return response;
  };
}

function deviceEffect(type, pin) {
  return {
    type,
    api: {
      endpoint: ['profile', typeToApi[type]],
      args: [pin],
    },
    meta: {
      contentId: 'deviceDetails',
    },
  };
}

export function verifyDevicePin(pin) {
  return deviceEffect(VERIFY_DEVICE_PIN, pin);
}

export function grantDeviceAuth(pin) {
  return deviceEffect(GRANT_DEVICE_AUTH, pin);
}
