import isEmpty from 'lodash/isEmpty';
import {
  BRAZE_FOLLOW_EVENT,
  BRAZE_UNFOLLOW_EVENT,
} from 'src/common/constants/braze';
import { lotame } from '../../client/analytics/lotame';
import { selectIsBrazeInitialized } from '../selectors/app';
import { brazeLogCustomEvent } from '../utils/braze/brazeHelper';
import { getFavoriteMessageText } from '../utils/getFavoriteMessageText';
import { logClientError } from './logging';
import { showMessage } from './messagePopover';
import makeFavoriteActions from './utils/makeFavoriteActions';

export const FAVORITE_CURRENTLY_PLAYING = 'FAVORITE_CURRENTLY_PLAYING';
export const UNFAVORITE_CURRENTLY_PLAYING = 'UNFAVORITE_CURRENTLY_PLAYING';
export const FAVORITE_CONTAINER_ITEM = 'FAVORITE_CONTAINER_ITEM';
export const UNFAVORITE_CONTAINER_ITEM = 'UNFAVORITE_CONTAINER_ITEM';
export const FAVORITE_PROFILE = 'FAVORITE_PROFILE';
export const UNFAVORITE_PROFILE = 'UNFAVORITE_PROFILE';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';

const ADD = 'add';
const REMOVE = 'remove';

const endpoints = {
  [FAVORITE_CURRENTLY_PLAYING]: ADD,
  [UNFAVORITE_CURRENTLY_PLAYING]: REMOVE,
  [FAVORITE_CONTAINER_ITEM]: ADD,
  [UNFAVORITE_CONTAINER_ITEM]: REMOVE,
  [FAVORITE_PROFILE]: ADD,
  [UNFAVORITE_PROFILE]: REMOVE,
  [REMOVE_FOLDER]: 'removeFolder',
};

/**
 * creates an action which makes the favorite call to the favorites api
 * @param type  - aciton type
 * @param guideId - guideId of the item being favorited/unfavorited
 * @param meta - metadata to be used in the reducer for this action
 * @returns {object}
 */
export const favoriteEffect = (type, guideId, meta) => (dispatch, getState) => {
  const state = getState();
  const isBrazeInitialized = selectIsBrazeInitialized(state);
  const { nowPlaying = {} } = state.player;
  const endpoint = endpoints[type];

  if (isBrazeInitialized && [ADD, REMOVE].includes(endpoint)) {
    const brazeEvent =
      endpoint === ADD ? BRAZE_FOLLOW_EVENT : BRAZE_UNFOLLOW_EVENT;
    try {
      brazeLogCustomEvent(brazeEvent, {
        guideId,
        boostEnabledStation: !isEmpty(nowPlaying?.boost),
      });
    } catch (err) {
      dispatch(
        logClientError({
          message: `Braze Custom Event: ${brazeEvent} Error`,
          context: {
            error: err,
          },
        }),
      );
      // NOTE: Don't break app functionality for Braze
    }
  }

  // TODO: Double check this...to make sure we canNOT return the non-dispatch'd object
  return dispatch({
    type,
    api: {
      endpoint: ['favorites', endpoint],
      args: [guideId],
    },
    meta: {
      guideId,
      ...meta,
    },
  });
};

/**
 * profile favorite actions
 */
export const {
  unFavorite: unFavoriteProfile,
  toggleFavorite: toggleProfileFavorite,
} = makeFavoriteActions(favoriteEffect, {
  favoriteType: FAVORITE_PROFILE,
  unFavoriteType: UNFAVORITE_PROFILE,
  removeFolderType: REMOVE_FOLDER,
});

/**
 * favorite action used in the player for the currently playing item
 * @param guideId
 * @returns {Function}
 */
export function favoriteCurrentlyPlayingItem(guideId = '') {
  return (dispatch, getState) => {
    const state = getState();
    const { nowPlaying = {} } = state.player;
    const favoriteMessages = getFavoriteMessageText(guideId, state);
    lotame.addInterests(nowPlaying);
    dispatch(
      favoriteEffect(FAVORITE_CURRENTLY_PLAYING, guideId, { nowPlaying }),
    )
      .then(({ value: { status } }) => {
        if (status === 200) {
          dispatch(showMessage(favoriteMessages.favorited));
        } else {
          dispatch(showMessage(favoriteMessages.favoriteError));
        }
      })
      .catch(() => {
        dispatch(showMessage(favoriteMessages.favoriteError));
      });
  };
}

/**
 * unfavorite action used in the player for the currently playing item
 * @param guideId
 * @returns {Function}
 */
export function unFavoriteCurrentlyPlayingItem(guideId) {
  return (dispatch, getState) => {
    const { nowPlaying } = getState().player;
    const favoriteMessages = getFavoriteMessageText(guideId, getState());
    dispatch(
      favoriteEffect(UNFAVORITE_CURRENTLY_PLAYING, guideId, { nowPlaying }),
    )
      .then(({ value: { status } }) => {
        if (status === 200) {
          dispatch(showMessage(favoriteMessages.unFavorited));
        } else {
          dispatch(showMessage(favoriteMessages.unFavoriteError));
        }
      })
      .catch(() => {
        dispatch(showMessage(favoriteMessages.unFavoriteError));
      });
  };
}

/**
 * favorite action used to favorite a guideItem rendered in a container
 * @param guideId
 * @returns {Function}
 */
export function favoriteContainerItem({ guideId }) {
  return (dispatch) => {
    dispatch(favoriteEffect(FAVORITE_CONTAINER_ITEM, guideId));
  };
}

/**
 * unfavorite action used to favorite a guideItem rendered in a container
 * @param guideId
 * @returns {Function}
 */
export function unFavoriteContainerItem({ guideId }) {
  return (dispatch) => {
    dispatch(favoriteEffect(UNFAVORITE_CONTAINER_ITEM, guideId));
  };
}
