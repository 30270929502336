import get from 'lodash/get';
import ValidationMessages from '../../constants/subscription/validationMessages';

export default function unWrapSubscriptionError(error) {
  const data = get(error, 'response.data', {});
  const { message } = data;
  if (ValidationMessages.includes(message)) {
    throw new Error(message);
  }
  // This isn't a subscription ValidationMessage that we can show on the client
  // so just throw it like we normally would.
  throw error;
}
