import { isTopic } from '@tunein/web-common';
import { addReportingMetadata } from '../actions/utils/addReportingMetadata';
import { CARD } from '../constants/containerTypes';
import {
  ATTRIBUTES_PROMPT,
  GAME_PROFILE_HEADER,
  PROFILE_HEADER,
} from '../constants/presentationTypes';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_TOPIC_PROFILE = 'FETCH_TOPIC_PROFILE';
export const SET_BRAZE_PROFILE_CARDS = 'SET_BRAZE_PROFILE_CARDS';

// exported for testing
export function mapProfile(profileResponse = {}) {
  const { items, metadata } = profileResponse;
  const cardItemIndex = items?.findIndex(
    (item) => item?.containerType === CARD,
  );

  if (cardItemIndex !== -1) {
    const cardItem = items.splice(cardItemIndex, 1)[0];
    const profile =
      cardItem?.children?.find((item) => {
        const layout = item?.presentation?.layout;
        return layout === PROFILE_HEADER || layout === GAME_PROFILE_HEADER;
      }) || {};

    const attributesPrompt =
      cardItem?.children?.find(
        (item) => item?.presentation?.layout === ATTRIBUTES_PROMPT,
      ) || {};

    profile.metadata = metadata || {};
    profile.containerItems = profileResponse?.items;
    profile.infoAttributes =
      attributesPrompt?.properties?.infoAttributes?.attributes || {};
    profile.rootGenre =
      profileResponse?.metadata?.properties?.classification
        ?.rootGenreClassification || null;

    profile.containerItems?.forEach(
      addReportingMetadata(metadata?.properties?.seoInfo || {}),
    );

    return profile;
  }

  return {
    isInactive: true,
    metadata,
    containerItems: [],
  };
}

export function fetchProfileEffect(
  { guideId, itemToken },
  type,
  categoryToPrepend,
) {
  const transform = (resp) => ({
    content: mapProfile(resp.data),
  });

  return {
    type,
    api: {
      endpoint: ['profile', 'getV3'],
      args: [guideId, { itemToken }],
      transform,
    },
    meta: {
      contentId: guideId,
      categoryToPrepend,
    },
  };
}

// exported for testing
export function fetchTopicEffect({ guideId, apiOptions }) {
  if (isTopic(guideId)) {
    const transform = (resp) => ({ content: resp.data.item });

    return {
      type: FETCH_TOPIC_PROFILE,
      api: {
        endpoint: ['profile', 'get'],
        args: [guideId],
        transform,
        ...apiOptions,
      },
      meta: {
        contentId: guideId,
      },
    };
  }

  return null;
}

export function fetchTopicData(guideId) {
  // NOTE: we don't want API failures for topic fetches to generate app errors (e.g. resulting in
  // 404/500 pages), so disabling them here.
  return fetchTopicEffect({ guideId, apiOptions: { registerAppError: false } });
}

export function fetchProfile(guideItem, categoryToPrepend) {
  return fetchProfileEffect(guideItem, FETCH_PROFILE, categoryToPrepend);
}

export function setBrazeProfileCards(guideId, brazeCards) {
  return {
    type: SET_BRAZE_PROFILE_CARDS,
    meta: {
      guideId,
      brazeCards,
    },
  };
}
