import { SET_PLAYBACK_HISTORY } from '../constants/playbackHistory';

export function setPlaybackHistory(id, progressPercentage) {
  return {
    type: SET_PLAYBACK_HISTORY,
    payload: {
      id,
      progressPercentage,
    },
  };
}
