import { MAP_VIEW } from '../../../constants/paths';
import { selectIsDiscord, selectIsMobile } from '../../../selectors/app';
import { selectIsDesktopNowPlayingEnabled } from '../../../selectors/config';
import { isSmall } from '../../../utils/breakpoints';
import isDesktop from '../../../utils/desktop/isDesktop';

export default function shouldOpenNowPlayingDialog(state) {
  const { app, dialog } = state;
  const isDesktopNowPlayingEnabled = selectIsDesktopNowPlayingEnabled(state);
  const isDiscord = selectIsDiscord(state);
  const showOnMobile =
    selectIsMobile(state) && !isSmall(app.breakpoint) && !isDiscord;
  const showOnDesktop = isDesktop() && isDesktopNowPlayingEnabled;
  const showOnDiscord = isDiscord && window.location.pathname !== MAP_VIEW;

  return (
    (showOnDesktop || showOnMobile || showOnDiscord) &&
    !dialog.isNowPlayingDialogOpen
  );
}
