import mapUserResponse from './data/user/mapResponse';

export const FETCH_USER = 'FETCH_USER';

function fetchUserEffect({ contentId }) {
  const transform = ({ data }) => ({ content: mapUserResponse(data) });

  return {
    type: FETCH_USER,
    api: {
      endpoint: ['profile', 'userByName'],
      args: [contentId],
      transform,
    },
    meta: {
      contentId,
    },
  };
}

export function selectUser({ contentId }) {
  return fetchUserEffect({ contentId });
}
