import { isAudioClip } from '@tunein/web-common';

export const FETCH_AUDIO_CLIP_PROFILE = 'FETCH_AUDIO_CLIP_PROFILE';

export function fetchAudioClipProfile(guideId, sourceGuideId) {
  if (guideId && isAudioClip(guideId)) {
    return {
      type: FETCH_AUDIO_CLIP_PROFILE,
      api: {
        endpoint: ['profile', 'get'],
        args: [guideId],
      },
      meta: {
        sourceGuideId,
      },
    };
  }

  return null;
}
