import get from 'lodash/get';
import { config } from '../constants/partners/alexa';
export const FETCH_PARTNER = 'FETCH_PARTNER';
export const ALEXA_LINK_SUCCESS = 'ALEXA_LINK_SUCCESS';
export const ALEXA_LINK_FAIL = 'ALEXA_LINK_FAIL';
export const ALEXA_CHECK_STATUS = 'ALEXA_CHECK_STATUS';
export const ALEXA_GET_URLS = 'ALEXA_GET_URLS';
export const LINK_SONOS = 'LINK_SONOS';

function skillResponseTransform(resp) {
  return {
    linkStatus: get(resp, 'data.accountLinkStatus'),
    skillStatus: get(resp, 'data.skillStatus'),
  };
}

export function fetchPartnersEffect(id) {
  const transform = (resp) => ({
    content: { ...resp.data },
  });

  return {
    type: FETCH_PARTNER,
    api: {
      endpoint: ['partners', 'get'],
      args: [id],
      transform,
    },
    meta: {
      contentId: id,
    },
  };
}

export function handleAlexaFailure() {
  return {
    type: ALEXA_LINK_FAIL,
  };
}

export function handleAlexaSuccess(authorizationCode) {
  return {
    type: ALEXA_LINK_SUCCESS,
    api: {
      endpoint: ['alexa', 'linkAlexa'],
      args: [{ authorizationCode, redirectUri: config.redirectUrl }],
      transform: skillResponseTransform,
    },
  };
}

export function getAlexaUrls() {
  const transform = (resp) => ({
    url: get(resp, 'data.lwaFallbackUrl'),
  });

  return (dispatch, getState) => {
    const serial = get(getState(), 'app.tuneInUserSerial');
    return dispatch({
      type: ALEXA_GET_URLS,
      api: {
        endpoint: ['alexa', 'getUrls'],
        transform,
      },
      meta: {
        serial,
      },
    });
  };
}

export function checkAlexaLinkStatus() {
  return {
    type: ALEXA_CHECK_STATUS,
    api: {
      endpoint: ['alexa', 'getStatus'],
      transform: skillResponseTransform,
    },
  };
}

// linkSonos action dispatches a request which tells the sonos device that it can complete partner pairing
export function linkSonos(authCode, state) {
  const formData = new FormData();
  formData.append('authCode', authCode);
  formData.append('state', state);

  return {
    type: LINK_SONOS,
    api: {
      endpoint: ['partners', 'linkSonos'],
      args: [formData],
    },
  };
}
