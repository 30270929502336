import { lotame } from '../../../client/analytics/lotame';
import { CATEGORIES } from '../../constants/containerTypes';
import { getFavoriteMessageText } from '../../utils/getFavoriteMessageText';
import isFollowingGuideId from '../../utils/isFollowingGuideId';
import { showMessage } from '../messagePopover';

export default function makeFavoriteActions(
  favoriteEffect,
  { favoriteType, unFavoriteType, removeFolderType },
) {
  /**
   * creates an action which which makes the favorite call to
   * the favorites api to favorite a guideItem
   * @param guideItem
   * @param wasAuthenticated
   * @returns {object}
   */
  function favorite({ guideItem, wasAuthenticated }) {
    const guideId = guideItem?.actions?.follow?.guideId;

    return favoriteEffect(favoriteType, guideId, {
      wasAuthenticated,
      switchDestinationGuideId: guideItem?.guideId,
    });
  }

  /**
   * creates an action which which makes the favorite call to
   * the favorites api to unFavorite a guideItem
   * @param guideItem
   * @param folder
   * @param index
   * @returns {object}
   */
  function unFavorite({ guideItem = {}, folder, index }) {
    const { actions, guideId, folderId } = guideItem;

    if (folderId) {
      return favoriteEffect(
        removeFolderType,
        { folderId },
        {
          index,
          folder: guideItem,
        },
      );
    }

    /**
     * Currently - 12/2020, there is an issue with the API, it returns an error of
     * "favoriteid or id is missing".
     * The id param is only being used by Categories, all else use the favoriteid
     * All items, except Categories have an actions.follow object
     * for this reason we pass the favoriteid when it's everything but a Category
     * and the guideId when it is.
     */
    const itemId = folderId || actions?.follow?.favoriteId || guideId;

    // Check to see if this is a Category, if so pass the guideId
    if (folder?.containerType === CATEGORIES) {
      return favoriteEffect(unFavoriteType, guideId, { folder, index });
    }

    return favoriteEffect(unFavoriteType, itemId, {
      folder,
      index,
      switchDestinationGuideId: actions?.follow?.guideId,
    });
  }

  /**
   * creates an action which toggles favorite state of a guideItem
   * @param params
   * @param wasAuthenticated
   * @returns {Function}
   */
  function toggleFavorite(params = {}, wasAuthenticated) {
    const { guideItem = {} } = params;
    const { userName, actions, guideId = '' } = guideItem;
    return (dispatch, getState) => {
      const state = getState();
      const meUserName = state.me?.details?.userName;
      if (meUserName && userName === meUserName) {
        return null;
      }

      const favoriteMessages = getFavoriteMessageText(guideId, state);
      const initialFollowState = actions?.follow?.isFollowing || false;
      const isFollowing = isFollowingGuideId(
        state,
        guideId,
        initialFollowState,
      );

      if (!isFollowing) {
        lotame.addInterests(guideItem);
        return dispatch(favorite({ ...params, wasAuthenticated }))
          .then(({ value: { status } }) => {
            if (status === 200) {
              dispatch(showMessage(favoriteMessages.favorited));
            } else {
              dispatch(showMessage(favoriteMessages.favoriteError));
            }
          })
          .catch(() => {
            dispatch(showMessage(favoriteMessages.favoriteError));
          });
      }
      return dispatch(unFavorite(params))
        .then(({ value: { status } }) => {
          if (status === 200) {
            dispatch(showMessage(favoriteMessages.unFavorited));
          } else {
            dispatch(showMessage(favoriteMessages.unFavoriteError));
          }
        })
        .catch(() => {
          dispatch(showMessage(favoriteMessages.unFavoriteError));
        });
    };
  }

  return {
    favorite,
    unFavorite,
    toggleFavorite,
  };
}
