function getMappedUserInfo(userInfo = {}) {
  const {
    subscriptionStatus: subStatus,
    username,
    ...otherUserInfo
  } = userInfo;

  return {
    id: username,
    userName: username, // camelCasing for consistency (TODO: re-review need for this)
    ...(subStatus && { subscriptionStatus: subStatus.toLowerCase() }),
    ...otherUserInfo, // Info like email, etc
  };
}

export default function mapProfileDetails({
  properties: { userInfo, profile } = {},
  guideId,
  actions,
  image,
  bannerImage,
  title,
  subtitle,
} = {}) {
  return {
    accountId: guideId,
    actions,
    image,
    bannerImage,
    title,
    subtitle,
    ...profile,
    ...getMappedUserInfo(userInfo),
  };
}

/*
example response info
{
  "Item": {
    "GuideId": "u207200",
    "Image": "https://graph.facebook.com/v2.6/872998053432/picture?width=300&height=300",
    "Title": "Nadine Kinnen",
    "Subtitle": "33d3289d",
    "Actions": {
      ...data for profile page
    },
    "Properties": {
      "UserInfo": {
        "Username": "33d3289d",
        "Email": "",
        "IsOnboarded": false,
        "SubscriptionStatus": "Active",
        "SubscriptionExpiresOn": "2020-02-08T21:57:06",
        "SubscriptionProviderId": 11,
        "SubscriptionProviderName": "recurly",
        "SubscriptionKey": "4a3db18e39465f16cea47641bcad3da3",
        "SubscriptionAccessRestricted": false,
        "HasProfileOwnership": false,
        "IsRegisteredUser": true,
        "IsVerifiedUser": false
      },
      "Profile": {
        "IsProfileVerified": false,
        "IsFollowingListPublic": true
      },
    },
  },
}
*/
