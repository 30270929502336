export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

let timer;

export function showMessage(message, timeout = 3000) {
  return (dispatch) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch({
        type: HIDE_MESSAGE,
      });
    }, timeout);

    return dispatch({
      type: SHOW_MESSAGE,
      message,
    });
  };
}

export function hideMessage() {
  return (dispatch) => {
    clearTimeout(timer);
    return dispatch({
      type: HIDE_MESSAGE,
    });
  };
}
