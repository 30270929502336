import feature from '../constants/analytics/categoryActionLabel/feature';
import { errorLabels } from '../constants/interestSelector';
import { logInterestSelectorAction } from './logging';

export const UPDATE_INTEREST_SELECTOR_ITEMS = 'UPDATE_INTEREST_SELECTOR_ITEMS';

export function updateInterestSelectorItems(
  guideIds = '',
  classifierLabel,
  remove = false,
) {
  return async (dispatch) => {
    const stringifiedGuideIds = `[${guideIds.toString()}]`;

    try {
      await dispatch({
        type: UPDATE_INTEREST_SELECTOR_ITEMS,
        api: {
          endpoint: ['favorites', 'updateInterests'],
          args: [stringifiedGuideIds, remove],
        },
      });

      dispatch(
        logInterestSelectorAction(
          remove ? feature.labels.remove : feature.labels.save,
          classifierLabel,
          stringifiedGuideIds,
        ),
      );
    } catch (e) {
      const errorLabel = remove
        ? errorLabels.errorBulkAddingInterests
        : errorLabels.errorBulkRemovingInterests;
      dispatch(
        logInterestSelectorAction(
          feature.labels.error,
          classifierLabel,
          errorLabel,
        ),
      );
    }
  };
}
