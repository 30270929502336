export const MINT_INIT = 'MINT_INIT';
export const DISPLAY_SLOT_LOADED = 'DISPLAY_SLOT_LOADED';

export function mintInit() {
  return {
    type: MINT_INIT,
  };
}

export function displaySlotLoaded(elementId) {
  return (dispatch) => {
    dispatch({
      type: DISPLAY_SLOT_LOADED,
      elementId,
    });
    // clear display slot store state immediately
    return dispatch({
      type: DISPLAY_SLOT_LOADED,
      elementId: '',
    });
  };
}
