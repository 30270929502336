import { reportingConfigMap } from 'src/common/constants/reporting/reportingConfigMap';
import { generateUniqueId } from 'src/common/utils/generateUniqueId';
import { getContentEventData } from 'src/common/utils/reporting/containerItems';
import {
  amazonVideoKeywords,
  app,
  player,
  playerClick,
} from '../constants/reporting/sandboxCategoryActionProps';
import { selectDiscordState } from '../selectors/app';
import { safeJsonStringify } from '../utils/object/safeJsonStringify';
import { createReportingContext } from '../utils/reporting';

export const LOG_REPORTING_EVENT = 'LOG_REPORTING_EVENT';
export const SET_PAGE_LOAD_ID = 'SET_PAGE_LOAD_ID';
export const SET_BREADCRUMB_ID = 'SET_BREADCRUMB_ID';

export function reportEvent({ endpoint, eventName, data = {}, options } = {}) {
  return (dispatch, getState) => {
    const state = getState();
    const context = createReportingContext(state);

    // eslint-disable-next-line no-param-reassign
    data.event = eventName;

    return dispatch({
      type: LOG_REPORTING_EVENT,
      api: {
        endpoint: ['reporting', endpoint],
        args: [{ event: data, context }, options],
      },
    });
  };
}

export function reportSandboxEvent(data = {}) {
  if (!data.props) {
    data.props = {};
  }

  return reportEvent({
    ...reportingConfigMap.sandbox,
    data,
    options: { sendImmediately: true },
  });
}

export function reportAdsPlaybackFailedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsPlaybackFailed, data });
}

export function reportAdsPlaybackStartedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsPlaybackStarted, data });
}

export function reportAdsPlaybackFinishedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsPlaybackFinished, data });
}

export function reportContentImpressionEvent(guideItem) {
  return (dispatch, getState) => {
    const data = getContentEventData({ guideItem, state: getState() });

    return dispatch(
      reportEvent({ ...reportingConfigMap.contentImpression, data }),
    );
  };
}

export function reportContentClickedEvent(guideItem) {
  return (dispatch, getState) => {
    const data = getContentEventData({
      guideItem,
      state: getState(),
      includeActionType: true,
    });
    return dispatch(
      reportEvent({ ...reportingConfigMap.contentClicked, data }),
    );
  };
}

export function reportUserPlayClickedEvent(data) {
  return reportEvent({ ...reportingConfigMap.userPlayClicked, data });
}

export function reportListenSessionStartedEvent(data) {
  return reportEvent({ ...reportingConfigMap.listenSessionStarted, data });
}

export function reportAppSessionStartedEvent() {
  return reportEvent({
    ...reportingConfigMap.applicationSessionStarted,
    options: { sendImmediately: true },
  });
}

export function reportAdsVideoAudioRollRequestedEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsVideoAudioRollRequested,
    data,
  });
}

export function reportAdsVideoAudioRollRequestFailedEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsVideoAudioRollRequestFailed,
    data,
  });
}

export function reportAdsVideoAudioRollResponseReceivedEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsVideoAudioRollResponseReceived,
    data,
  });
}

export function reportAdsVideoAudioRollEligibilityDecidedEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsVideoAudioRollEligibilityDecided,
    data,
  });
}

// Start of Display Ad Reporting Events
export function reportAdsDisplayRequestedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsDisplayRequested, data });
}

export function reportAdsDisplayResponseReceivedEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsDisplayResponseReceived,
    data,
  });
}

export function reportAdsDisplayRequestFailedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsDisplayRequestFailed, data });
}

export function reportAdsDisplayImpressionEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsDisplayImpression, data });
}

export function reportAdsDisplayViewabilityStatusEvent(data) {
  return reportEvent({
    ...reportingConfigMap.adsDisplayViewabilityStatus,
    data,
  });
}

export function reportAdsDisplayClickedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsDisplayClicked, data });
}
// End of Display Ad Reporting Events

// Start of Instream Ad Reporting Events
export function reportAdsInstreamReceivedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsInstreamReceived, data });
}

export function reportAdsInstreamStartedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsInstreamStarted, data });
}

export function reportAdsInstreamQuartileStatusEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsInstreamQuartileStatus, data });
}

export function reportAdsInstreamCompletedEvent(data) {
  return reportEvent({ ...reportingConfigMap.adsInstreamCompleted, data });
}
// End of Instream Ad Reporting Events

// Start of Discord Reporting Events
export function reportDiscordOauthAccepted(data) {
  return reportEvent({
    ...reportingConfigMap.discordOauthAccepted,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordOauthDenied(data) {
  return reportEvent({
    ...reportingConfigMap.discordOauthDenied,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordActivityInitiated(data) {
  return reportEvent({
    ...reportingConfigMap.discordActivityInitiated,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordActivityEnded(data) {
  return reportEvent({
    ...reportingConfigMap.discordActivityEnded,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordHostAssigned(data) {
  return reportEvent({
    ...reportingConfigMap.discordHostAssigned,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordSharedModeActivated(data) {
  return reportEvent({
    ...reportingConfigMap.discordSharedModeActivated,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordSharedModeDeactivated(data) {
  return reportEvent({
    ...reportingConfigMap.discordSharedModeDeactivated,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordUserJoined(data) {
  return reportEvent({
    ...reportingConfigMap.discordUserJoined,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordUserLeft(data) {
  return reportEvent({
    ...reportingConfigMap.discordUserLeft,
    data,
    options: { sendImmediately: true },
  });
}

export function reportMaxParticipantsReached(data) {
  return reportEvent({
    ...reportingConfigMap.discordMaxParticipantsReached,
    data,
    options: { sendImmediately: true },
  });
}

export function reportDiscordError(errorType, errorMessage) {
  return reportSandboxEvent({
    category: app.category,
    action: app.actions.error,
    props: {
      errorType,
      errorMessage,
    },
  });
}
// End of Discord Reporting Events

// Content Reporting Events
export function setPageLoadId() {
  return {
    type: SET_PAGE_LOAD_ID,
    pageLoadId: generateUniqueId().toString(),
  };
}

export function setBreadcrumbId(options) {
  return {
    type: SET_BREADCRUMB_ID,
    breadcrumbId: options?.reset ? null : generateUniqueId().toString(),
  };
}
// End of Content Reporting Events

export function reportPlayerError(context) {
  const {
    playerName = '',
    streamId = '',
    url = '',
    format = '',
    error = {},
    originalErrorMessage = '',
    wasInSteadyState,
  } = context || {};

  return reportSandboxEvent({
    category: player.category,
    action: player.actions.error,
    props: {
      playerName,
      streamId,
      url,
      format,
      errorData: safeJsonStringify(error.data) || '',
      errorMessage: error.message || originalErrorMessage || 'Unknown error',
      errorType: error.type || 'Unknown',
      isMediaError: error.isMediaError?.toString() || '',
      isNetworkError: error.isNetworkError?.toString() || '',
      wasInSteadyState: wasInSteadyState?.toString() || 'false',
    },
  });
}

export function reportPlayerStopClick() {
  return (dispatch, getState) => {
    const discordState = selectDiscordState(getState());

    if (!discordState.canControlPlayback) {
      return;
    }

    return dispatch(
      reportSandboxEvent({
        category: playerClick.category,
        action: playerClick.actions.stop,
      }),
    );
  };
}

export function reportPlayerPauseClick() {
  return (dispatch, getState) => {
    const discordState = selectDiscordState(getState());

    if (!discordState.canControlPlayback) {
      return;
    }

    return dispatch(
      reportSandboxEvent({
        category: playerClick.category,
        action: playerClick.actions.pause,
      }),
    );
  };
}

export function reportPlayerRejectedReason(reason) {
  return reportSandboxEvent({
    category: player.category,
    action: player.actions.reject,
    props: { errorType: reason },
  });
}

export function reportAmazonKeywordsRequestFailed(adType, errorInfo) {
  const { errorCode, errorDescription } = errorInfo;

  return reportSandboxEvent({
    category: amazonVideoKeywords.category,
    action: amazonVideoKeywords.actions.error,
    props: {
      AD_TYPE: adType,
      ErrorCode: errorCode,
      ErrorDescription: errorDescription,
    },
  });
}

export function reportPageLoadMetrics() {
  return (dispatch) => {
    const performanceEntries =
      performance?.getEntriesByType?.('navigation')?.[0];
    const loadTime = Math.round(
      performanceEntries?.loadEventEnd - performanceEntries?.startTime,
    );

    if (!loadTime || !performanceEntries) {
      return;
    }

    return dispatch(
      reportSandboxEvent({
        category: app.category,
        action: app.actions.performance,
        props: {
          load: Math.round(
            performanceEntries?.loadEventEnd - performanceEntries?.startTime ||
              0,
          ).toString(),
        },
      }),
    );
  };
}
