import querystring from 'querystring';
import { addReportingMetadata } from '../actions/utils/addReportingMetadata';
import recentSearches from '../utils/storage/recentSearches';

export const FETCH_SEARCH = 'FETCH_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const FETCH_PREPOPULATE_SEARCH = 'FETCH_PREPOPULATE_SEARCH';
export const ADD_RECENT_SEARCH = 'ADD_RECENT_SEARCH';
export const GET_RECENT_SEARCHES = 'GET_RECENT_SEARCHES';
export const REMOVE_RECENT_SEARCH = 'REMOVE_RECENT_SEARCH';
export const CLEAR_RECENT_SEARCHES = 'CLEAR_RECENT_SEARCHES';

export const searchResultsContentId = 'searchResults';
export const searchSuggestionsContentId = 'searchSuggestions';

const searchEndpoints = {
  [FETCH_SEARCH]: 'search',
  [FETCH_PREPOPULATE_SEARCH]: 'prepopulateSearch',
};

function fetchSearchEffect(
  type,
  query,
  attributes = '',
  disableLoadingState,
  itemToken,
  autocomplete,
) {
  const transform = (resp) => {
    const { items, metadata } = resp.data;

    items?.forEach(addReportingMetadata(metadata?.properties?.seoInfo || {}));

    return {
      content: {
        containerItems: items,
        itemsStyles: metadata?.styles || {},
      },
    };
  };
  const endpoint = searchEndpoints[type];
  const params = querystring.parse(attributes);

  if (itemToken) {
    params.itemToken = itemToken;
  }

  if (autocomplete) {
    params.autocomplete = autocomplete;
  }

  return {
    type,
    api: {
      endpoint: ['profile', endpoint],
      args: [query, params],
      transform,
    },
    meta: {
      contentId: autocomplete
        ? searchSuggestionsContentId
        : searchResultsContentId,
      disableLoadingState,
      searchTerm: query,
    },
  };
}

export function performSearch({
  query,
  attributes,
  disableLoadingState,
  itemToken,
  autocomplete,
}) {
  return fetchSearchEffect(
    FETCH_SEARCH,
    query,
    attributes,
    disableLoadingState,
    itemToken,
    autocomplete,
  );
}

export function performPrepopulateSearch({
  query,
  attributes,
  disableLoadingState,
}) {
  return fetchSearchEffect(
    FETCH_PREPOPULATE_SEARCH,
    query,
    attributes,
    disableLoadingState,
  );
}

export function addRecentSearch(term) {
  return { type: ADD_RECENT_SEARCH, recentSearches: recentSearches.add(term) };
}

export function getRecentSearches() {
  return { type: GET_RECENT_SEARCHES, recentSearches: recentSearches.get() };
}

export function removeRecentSearch(termIndex) {
  return {
    type: REMOVE_RECENT_SEARCH,
    recentSearches: recentSearches.remove(termIndex),
  };
}

export function clearRecentSearches() {
  return {
    type: CLEAR_RECENT_SEARCHES,
    recentSearches: recentSearches.clear(),
  };
}

export function clearSearch(contentId) {
  return {
    type: CLEAR_SEARCH,
    contentId: contentId || searchResultsContentId,
  };
}
