import account from '../constants/analytics/categoryActionLabel/account';
import boost from '../constants/analytics/categoryActionLabel/boost';
import contentCard from '../constants/analytics/categoryActionLabel/contentCard';
import coupon from '../constants/analytics/categoryActionLabel/coupon';
import eula from '../constants/analytics/categoryActionLabel/eula';
import feature from '../constants/analytics/categoryActionLabel/feature';
import mobileDownload from '../constants/analytics/categoryActionLabel/mobileDownload';
import mobileWeb from '../constants/analytics/categoryActionLabel/mobileWeb';
import play from '../constants/analytics/categoryActionLabel/play';
import subscribe from '../constants/analytics/categoryActionLabel/subscribe';
import unsubscribe from '../constants/analytics/categoryActionLabel/unsubscribe';
import web from '../constants/analytics/categoryActionLabel/web';
import { selectAudioPrerollInterval } from '../selectors/config';
import {
  selectListenId,
  selectNowPlaying,
  selectTuneContextLabel,
} from '../selectors/player';
import { isLocalEnv } from '../utils/environment';
import formatClientErrorData from '../utils/formatClientErrorData';
import isServer from '../utils/isServer';
import { appendPartnerIdToLabel, appendToLabel } from '../utils/logging';

export const LOG_CATEGORY_ACTION_LABEL = 'LOG_CATEGORY_ACTION_LABEL';
export const LOG_CLIENT_ERROR = 'LOG_CLIENT_ERROR';
export const LOG_CLIENT_INFO = 'LOG_CLIENT_INFO';
export const LOG_INFO = 'LOG_INFO';
export const REMEMBER_CONTENT_CARD_WITH_IMPRESSION_REPORTED =
  'REMEMBER_CONTENT_CARD_WITH_IMPRESSION_REPORTED';
export const UPDATE_CARDS_VALIDATION_ERRORS = 'UPDATE_CARDS_VALIDATION_ERRORS';

export function logClientError(errorData = {}) {
  return (dispatch) => {
    if (!errorData.message || typeof errorData.context !== 'object') {
      throw new Error(
        'The `message` and `context` attributes must be defined.',
      );
    }

    if (isServer()) {
      return null;
    }

    // eslint-disable-next-line no-param-reassign
    errorData = formatClientErrorData(errorData);

    if (isLocalEnv()) {
      // eslint-disable-next-line no-console
      console.error(errorData.message, errorData.context);
    }

    return dispatch({
      type: LOG_CLIENT_ERROR,
      api: {
        endpoint: ['logging', 'errorLog'],
        args: [errorData],
      },
      meta: errorData,
    });
  };
}

export function logClientInfo(data = {}) {
  return (dispatch) => {
    if (!data.message || typeof data.context !== 'object') {
      throw new Error(
        'The `message` and `context` attributes must be defined.',
      );
    }

    if (isServer()) {
      return null;
    }

    return dispatch({
      type: LOG_CLIENT_INFO,
      api: {
        endpoint: ['logging', 'infoLog'],
        args: [data],
      },
      meta: data,
    });
  };
}

export function logCategoryActionLabel(event, params = {}) {
  return (dispatch) =>
    dispatch({
      type: LOG_CATEGORY_ACTION_LABEL,
      api: {
        endpoint: ['logging', 'eventLog'],
        args: [{ event, params }],
      },
    }).catch((error) =>
      dispatch(
        logClientError({
          message: `EVENT LOGGING FAILURE: ${error.message}`,
          context: {
            event: typeof event === 'string' ? event : JSON.stringify(event),
            params,
            error,
          },
        }),
      ),
    );
}

function logPlayCategoryActionLabel(event, moreParams) {
  return (dispatch, getState) => {
    const state = getState();

    const tuneContextLabel = selectTuneContextLabel(state);
    const listenId = selectListenId(state);
    const label = tuneContextLabel
      ? `${event.label}.${tuneContextLabel}`
      : event.label;

    return dispatch(
      logCategoryActionLabel({ ...event, label }, { ...moreParams, listenId }),
    );
  };
}

export const logEULAActivity = (
  subcategory,
  action,
  { loggedInValue = '', eulaVersion = '', eulaType = '' }, // label
) =>
  logCategoryActionLabel({
    category: `${eula.category}.${subcategory}`,
    action,
    label: eula.subcategory[subcategory].action[action].getLabel({
      loggedInValue,
      eulaVersion,
      eulaType,
    }),
  });

export function logFeatureActivity(action, label) {
  const event = {
    category: feature.category,
    action,
    label,
  };
  return logCategoryActionLabel(event);
}

export function logMobileDownloadClick(newLabel, guideId) {
  const event = {
    category: mobileDownload.category,
    action: mobileDownload.action,
    label: newLabel,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event, { id: guideId }));
}

export function logWebActivity(newAction, newLabel, guideId) {
  const event = {
    category: web.category,
    action: newAction,
    label: newLabel,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event, { id: guideId }));
}

export function logSubscribeActivity(
  newAction,
  newLabel,
  { guideId, partnerId } = {},
) {
  let modifiedLabel = appendPartnerIdToLabel(newLabel, partnerId);
  modifiedLabel = appendToLabel(modifiedLabel, guideId);

  const event = {
    category: subscribe.category,
    action: newAction,
    label: modifiedLabel,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event, { id: guideId }));
}

export function logUnsubscribeActivity(newAction, newLabel, guideId) {
  const event = {
    category: unsubscribe.category,
    action: newAction,
    label: newLabel,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event, { id: guideId }));
}

export function logMobileWebActivity(newAction, newLabel) {
  const event = {
    category: mobileWeb.category,
    action: newAction,
    label: newLabel,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event));
}

export function logAccountActivity(action, labelArray) {
  const label = labelArray.filter(Boolean).join('.');
  const event = {
    category: account.category,
    action,
    label,
  };

  return (dispatch) => dispatch(logCategoryActionLabel(event));
}

export function logSignInClick(label, source) {
  return logAccountActivity(account.actions.click, [
    account.labels.signIn,
    label,
    source,
  ]);
}

export function logSignUpClick(label, source) {
  return logAccountActivity(account.actions.click, [
    account.labels.signUp,
    label,
    source,
  ]);
}

export function logSignInDismiss() {
  return logAccountActivity(account.actions.dismiss, [account.labels.signIn]);
}

export function logSignUpDismiss() {
  return logAccountActivity(account.actions.dismiss, [account.labels.signUp]);
}

export function logPartnerAuthConfirmationShow(source) {
  return logAccountActivity(account.actions.show, [
    account.labels.confirmation,
    account.labels.partner,
    source,
  ]);
}

export function logPartnerAuthConfirmationAllowClick(source, productSku) {
  return logAccountActivity(account.actions.allow, [
    account.labels.confirmation,
    productSku,
    account.labels.partner,
    source,
  ]);
}

export function logPartnerAuthConfirmationDenyClick(source) {
  return logAccountActivity(account.actions.deny, [
    account.labels.confirmation,
    account.labels.partner,
    source,
  ]);
}

export function logPlayStart({ guideId: id, isAutoplayed = false, itemToken }) {
  const event = {
    category: play.category,
    action: play.actions.start,
    label: isAutoplayed ? play.labels.auto : play.labels.manual,
  };

  return logPlayCategoryActionLabel(event, {
    id,
    itemToken,
  });
}

export function logPrerollEligible({ audioEnabled, videoEnabled }) {
  return (dispatch, getState) => {
    const audioPrerollIntervalSeconds = selectAudioPrerollInterval(getState());
    const label = [
      play.labels.preroll,
      `video.${videoEnabled}`,
      `audio.${audioEnabled}`,
      'skip.false',
      `audioInterval.${audioPrerollIntervalSeconds}`,
      `videoInterval.${audioPrerollIntervalSeconds}`,
    ].join('.');
    const event = {
      category: play.category,
      action: play.actions.start,
      label,
    };

    dispatch(logPlayCategoryActionLabel(event));
  };
}

function logTuneTimeReport(
  action,
  id,
  time,
  playerNameList,
  itemToken,
  hadPreroll,
  hasAdBlocker,
) {
  const { preroll, noPreroll, adBlockerEnabled } = play.labels;
  const prerollLabelSuffix = hadPreroll ? preroll : noPreroll;
  const finalLabelSuffix = hasAdBlocker ? adBlockerEnabled : prerollLabelSuffix;
  const event = {
    category: play.category,
    action,
    label: [playerNameList?.join('.'), finalLabelSuffix].join('.'),
    value: time,
  };

  return (dispatch) =>
    dispatch(logPlayCategoryActionLabel(event, { id, itemToken }));
}

export function logStreamEvaluationSuccessTime({
  guideId,
  time,
  playerNameList,
  itemToken,
  hadPreroll,
}) {
  return logTuneTimeReport(
    play.actions.successMs,
    guideId,
    time,
    playerNameList,
    itemToken,
    hadPreroll,
  );
}

export function logStreamEvaluationFailTime({
  guideId,
  time,
  playerNameList,
  itemToken,
  hadPreroll,
}) {
  return logTuneTimeReport(
    play.actions.failMs,
    guideId,
    time,
    playerNameList,
    itemToken,
    hadPreroll,
  );
}

export function logTotalTuneSuccessTime({
  guideId,
  time,
  playerNameList,
  itemToken,
  hadPreroll,
}) {
  return logTuneTimeReport(
    play.actions.totalSuccessMs,
    guideId,
    time,
    playerNameList,
    itemToken,
    hadPreroll,
  );
}

export function logTotalTuneFailTime({
  guideId,
  time,
  playerNameList,
  itemToken,
  hadPreroll,
  hasAdBlocker,
}) {
  return logTuneTimeReport(
    play.actions.totalFailMs,
    guideId,
    time,
    playerNameList,
    itemToken,
    hadPreroll,
    hasAdBlocker,
  );
}

export function logPlayStartQualified({ guideId: id, itemToken }) {
  const event = {
    category: play.category,
    action: play.actions.start,
    label: play.labels.qualified,
  };

  return logPlayCategoryActionLabel(event, { id, itemToken });
}

export function logPlayStartNext({ guideId: id, itemToken }) {
  const event = {
    category: play.category,
    action: play.actions.start,
    label: play.labels.next,
  };

  return logPlayCategoryActionLabel(event, { id, itemToken });
}

export function logInfo(...args) {
  return {
    type: LOG_INFO,
    data: args,
  };
}

export function logCouponActivity(couponAction, campaign, couponCode) {
  const event = {
    category: coupon.category,
    action: couponAction,
    label: [campaign, couponCode].filter(Boolean).join('.'),
  };

  return logCategoryActionLabel(event);
}

export function logMessageOfTheDayAction(label, value) {
  const event = {
    category: feature.category,
    action: feature.actions.messageOfTheDay,
    label: value ? `${label}.${value}` : label,
  };

  return logCategoryActionLabel(event);
}

export function logInterestSelectorAction(
  behaviorLabel = feature.labels.dismiss,
  classifierLabel,
  dataLabel,
) {
  const event = {
    category: feature.category,
    action: feature.actions.interestSelector,
    label:
      behaviorLabel === feature.labels.dismiss
        ? behaviorLabel
        : [behaviorLabel, classifierLabel, dataLabel].filter(Boolean).join('.'),
  };

  return logCategoryActionLabel(event);
}

export function logLinkAlexaAction(label) {
  const event = {
    category: feature.category,
    action: feature.actions.linkAlexa,
    label,
  };

  return logCategoryActionLabel(event);
}

export function logBoostFeatureAction(action, label) {
  return (dispatch, getState) => {
    const state = getState();

    const nowPlaying = selectNowPlaying(state);
    const listenId = selectListenId(state);

    const event = {
      category: boost.category,
      action,
      label,
    };

    return dispatch(
      logCategoryActionLabel(event, {
        stationId: nowPlaying?.primaryGuideId,
        ...(label === boost.labels.tooltip && { listenId }),
      }),
    );
  };
}

export function logBoostOptInAction(label) {
  return logBoostFeatureAction(boost.actions.optin, label);
}

export function logBoostOptOutAction(label) {
  return logBoostFeatureAction(boost.actions.optout, label);
}

export function logBoostEnabledAction(label) {
  return logBoostFeatureAction(boost.actions.enabled, label);
}

export function logBoostDisabledAction(label) {
  return logBoostFeatureAction(boost.actions.disabled, label);
}

/**
 Braze Content Cards reporting
 */
export function logReceivedContentCardsCount(count = 0) {
  return logCategoryActionLabel({
    category: contentCard.category,
    action: contentCard.actions.count,
    label: count,
  });
}

export function logReceivedDuplicatedCards(duplicatedCardIds) {
  return logCategoryActionLabel({
    category: contentCard.category,
    action: contentCard.actions.duplicatedcards,
    label: duplicatedCardIds.filter(Boolean).join(','),
  });
}

export function logContentCardImpression({
  brazeCardId,
  screenId,
  containerIndex,
  index,
}) {
  return logCategoryActionLabel({
    category: contentCard.category,
    action: contentCard.actions.impression,
    label: [brazeCardId, screenId, `${containerIndex}`, `${index}`]
      .filter(Boolean)
      .join('.'),
  });
}

export function logContentCardClick({
  brazeCardId,
  screenId,
  containerIndex,
  index,
}) {
  return logCategoryActionLabel({
    category: contentCard.category,
    action: contentCard.actions.click,
    label: [brazeCardId, screenId, `${containerIndex}`, `${index}`]
      .filter(Boolean)
      .join('.'),
  });
}

export function logContentCardFailures(validationErrors) {
  return (dispatch) => {
    validationErrors.forEach(({ guideId, cardId, index, errors }) => {
      const cardIdParam = cardId ? `cardId=${cardId}` : '';
      const screenId = guideId ? `screenId=${guideId}` : '';
      const screenLocation = index ? `screenLocation=${index}` : '';

      dispatch(
        logCategoryActionLabel({
          category: contentCard.category,
          action: contentCard.actions.failure,
          label: [cardIdParam, screenId, screenLocation, `errorCodes=${errors}`]
            .filter(Boolean)
            .join('.'),
        }),
      );
    });
  };
}

export function rememberContentCardWithImpressionReported(contentCardId) {
  return {
    type: REMEMBER_CONTENT_CARD_WITH_IMPRESSION_REPORTED,
    contentCardId,
  };
}

export function updateCardValidationErrors(errors) {
  return { type: UPDATE_CARDS_VALIDATION_ERRORS, errors };
}
/**
 Braze Content Cards reporting
*/
