import { fetchProfileIfInQueryByLocation } from '../subscriptionFlow';

// used in subscription flow to display station-specific artwork and title
// (see files: PaymentCompletionConnector)
// this function you can use together with connectAsync
export default function fetchProfileIfInQuery({
  location,
  store: { dispatch, getState },
}) {
  return fetchProfileIfInQueryByLocation(location, true)(dispatch, getState);
}
