export const FETCH_CONFIG = 'FETCH_CONFIG';

export function fetchConfig(params) {
  return {
    type: FETCH_CONFIG,
    api: {
      endpoint: ['config', 'get'],
      args: [params],
    },
  };
}
