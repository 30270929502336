import mobileWeb from '../constants/analytics/categoryActionLabel/mobileWeb';
import subscribe from '../constants/analytics/categoryActionLabel/subscribe';
import web from '../constants/analytics/categoryActionLabel/web';
import { hidePopoutPlayerDialogCookie } from '../constants/cookies';
import inAppMessageDialogKeys from '../constants/dialogs/inAppMessageDialogKeys';
import { selectIsUserSubscribed } from '../selectors/me';
import {
  closeBranchJourney,
  reopenBranchJourney,
} from '../utils/branch/branchHelper';
import { getCookie, setCookie } from '../utils/cookie';

import { NOW_PLAYING_GUIDE_ID } from '../constants/dialogs/nowPlaying';
import { WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER } from '../constants/experiments/dialog';
import { OPEN_IN_EMBED_DIALOG_FREQUENCY } from '../constants/experiments/popout';
import { pending } from '../reducers/utils/asyncActionNameSuffixes';
import { getSelectCategory } from '../selectors/categories';
import {
  selectExperiment,
  selectIsDesktopNowPlayingEnabled,
} from '../selectors/config';
import { fetchCategory, fetchCategoryWithContextEffect } from './browse';
import {
  logInterestSelectorAction,
  logMobileWebActivity,
  logSubscribeActivity,
  logWebActivity,
} from './logging';
import { sharingFromProfile } from './share';
import shouldOpenNowPlayingDialog from './utils/dialog/shouldOpenNowPlayingDialog';

/** ********* upsell dialog ********* * */
export const OPEN_UPSELL_DIALOG = 'OPEN_UPSELL_DIALOG';
export const CLOSE_UPSELL_DIALOG = 'CLOSE_UPSELL_DIALOG';
export const RESET_OPEN_UPSELL_DIALOG_REQUEST =
  'RESET_OPEN_UPSELL_DIALOG_REQUEST';
export const OPEN_POPOUT_PLAYER_DIALOG = 'OPEN_POPOUT_PLAYER_DIALOG';
export const CLOSE_POPOUT_PLAYER_DIALOG = 'CLOSE_POPOUT_PLAYER_DIALOG';

export function openUpsellDialog(
  label,
  guideId = null,
  topicGuideId = null,
  products = [],
  image = null,
  title = null,
) {
  return {
    type: OPEN_UPSELL_DIALOG,
    label,
    guideId,
    topicGuideId,
    products,
    image,
    title,
  };
}

export function closeUpsellDialog() {
  return {
    type: CLOSE_UPSELL_DIALOG,
  };
}

export function openUpsellAndTrackActivity(
  label,
  guideId,
  topicGuideId,
  products,
  image,
  title,
) {
  return (dispatch) => {
    dispatch(logSubscribeActivity(subscribe.actions.show, label, { guideId }));
    dispatch(
      openUpsellDialog(label, guideId, topicGuideId, products, image, title),
    );
  };
}

export function closeUpsellAndTrackActivity(action) {
  return (dispatch, getState) => {
    const { dialog } = getState();
    dispatch(
      logSubscribeActivity(action, dialog.upsellLabel, {
        guideId: dialog.upsellGuideId,
      }),
    );
    dispatch(closeUpsellDialog());
  };
}

/** ********* download app dialog ********* * */
export const OPEN_DOWNLOAD_APP_DIALOG = 'OPEN_DOWNLOAD_APP_DIALOG';
export const CLOSE_DOWNLOAD_APP_DIALOG = 'CLOSE_DOWNLOAD_APP_DIALOG';

export function openDownloadAppDialog() {
  return {
    type: OPEN_DOWNLOAD_APP_DIALOG,
  };
}

export function closeDownloadAppDialog() {
  return {
    type: CLOSE_DOWNLOAD_APP_DIALOG,
  };
}

function shouldShowPlayerOverDialog(state) {
  return selectExperiment(
    state,
    WEB_OPEN_INSTALL_APP_DIALOG_SHOW_PLAYER,
    false,
  );
}

export function openDownloadAppDialogAndTrack() {
  return (dispatch, getState) => {
    if (shouldShowPlayerOverDialog(getState())) {
      closeBranchJourney();
    }
    dispatch(openDownloadAppDialog());
    dispatch(
      logMobileWebActivity(
        mobileWeb.actions.show,
        mobileWeb.labels.appDownloadDialog,
      ),
    );
  };
}

export function closeDownloadAppDialogAndTrack() {
  return (dispatch, getState) => {
    if (shouldShowPlayerOverDialog(getState())) {
      reopenBranchJourney();
    }
    dispatch(closeDownloadAppDialog());
    dispatch(
      logMobileWebActivity(
        mobileWeb.actions.close,
        mobileWeb.labels.appDownloadDialog,
      ),
    );
  };
}

/** ********* Now Playing dialog ********* * */

export const CLOSE_NOW_PLAYING_DIALOG = 'CLOSE_NOW_PLAYING_DIALOG';
export const OPEN_NOW_PLAYING_DIALOG = 'OPEN_NOW_PLAYING_DIALOG';
export const FETCH_NOW_PLAYING_CATEGORY = 'FETCH_NOW_PLAYING_CATEGORY';

export function closeNowPlayingDialog() {
  return {
    type: CLOSE_NOW_PLAYING_DIALOG,
  };
}

export function openNowPlayingDialog() {
  return (dispatch, getState) => {
    if (shouldOpenNowPlayingDialog(getState())) {
      dispatch({ type: OPEN_NOW_PLAYING_DIALOG });
    }
  };
}

export function closeNowPlayingDialogAndTrack() {
  return (dispatch) => {
    dispatch(closeNowPlayingDialog());
    dispatch(
      logMobileWebActivity(
        mobileWeb.actions.close,
        mobileWeb.labels.nowPlayingDialog,
      ),
    );
  };
}

export function fetchNowPlayingItems(guideId) {
  return (dispatch, getState) => {
    const isNPEnabled = selectIsDesktopNowPlayingEnabled(getState());
    dispatch(
      fetchCategoryWithContextEffect(
        FETCH_NOW_PLAYING_CATEGORY,
        NOW_PLAYING_GUIDE_ID,
        guideId,
        isNPEnabled,
      ),
    );
  };
}

/** ********* in app message dialog ********* * */
export const OPEN_IN_APP_MESSAGE_DIALOG = 'OPEN_IN_APP_MESSAGE_DIALOG';
export const CLOSE_IN_APP_MESSAGE_DIALOG = 'CLOSE_IN_APP_MESSAGE_DIALOG';

export function requestInAppMessageDialogOpen(key, guideId) {
  return {
    type: OPEN_IN_APP_MESSAGE_DIALOG,
    api: {
      endpoint: ['infoMessages', 'get'],
      args: [key],
    },
    meta: {
      contentId: key,
      guideId,
    },
  };
}

export function cancelInAppMessageDialogOpenRequest() {
  return {
    type: CLOSE_IN_APP_MESSAGE_DIALOG,
  };
}

// used to prevent InAppMessageDialog from opening if DesktopDialog is already open
export function determineInAppMessageDialogRequestBehavior(
  inAppMessageKey,
  guideId,
) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.dialog.isDesktopDialogOpen) {
      return;
    }
    // If DesktopDialog is NOT open, request InAppMessageDialog to open,
    // but don't log web activity. Delegating web activity logging to InAppMessageDialog,
    // if and when it determines dialog is allowed to open
    if (inAppMessageKey !== inAppMessageDialogKeys.backBuffer) {
      dispatch(requestInAppMessageDialogOpen(inAppMessageKey, guideId));
      return;
    }
    // Only open the back-buffer dialog if the user is subscribed as this is a
    // premium only feature.
    if (selectIsUserSubscribed(state)) {
      dispatch(requestInAppMessageDialogOpen(inAppMessageKey, guideId));
    }
  };
}

/** ********* desktop dialog ********* * */
export const OPEN_DESKTOP_DIALOG = 'OPEN_DESKTOP_DIALOG';
export const CLOSE_DESKTOP_DIALOG = 'CLOSE_DESKTOP_DIALOG';

export function openDesktopDialog() {
  return {
    type: OPEN_DESKTOP_DIALOG,
  };
}

export function closeDesktopDialog() {
  return {
    type: CLOSE_DESKTOP_DIALOG,
  };
}

export function openDesktopDialogAndTrack(label) {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.show, label));
    dispatch(openDesktopDialog());
  };
}

export function closeDesktopDialogAndTrack(label) {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.close, label));
    dispatch(closeDesktopDialog());
  };
}

export function tapDesktopDialogAndTrack(label) {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.tap, label));
    dispatch(closeDesktopDialog());
  };
}

/** ********* text me the app dialog ********* * */
export const OPEN_TEXT_ME_THE_APP_DIALOG = 'OPEN_TEXT_ME_THE_APP_DIALOG';
export const CLOSE_TEXT_ME_THE_APP_DIALOG = 'CLOSE_TEXT_ME_THE_APP_DIALOG';

function openTextMeTheAppDialog() {
  return {
    type: OPEN_TEXT_ME_THE_APP_DIALOG,
  };
}

export function openTextMeTheAppDialogAndTrack() {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.show, web.labels.textMeTheApp));
    dispatch(openTextMeTheAppDialog());
  };
}

function closeTextMeTheAppDialog() {
  return {
    type: CLOSE_TEXT_ME_THE_APP_DIALOG,
  };
}

export function closeTextMeTheAppDialogAndTrack() {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.close, web.labels.textMeTheApp));
    dispatch(closeTextMeTheAppDialog());
  };
}

export function tapTextMeTheAppDialogAndTrack() {
  return (dispatch) => {
    dispatch(logWebActivity(web.actions.tap, web.labels.textMeTheApp));
  };
}

export function requestPopoutPlayerDialog() {
  return (dispatch, getState) => {
    if (getCookie(hidePopoutPlayerDialogCookie.name)) {
      return;
    }

    const frequency = selectExperiment(
      getState(),
      OPEN_IN_EMBED_DIALOG_FREQUENCY,
      1,
    );
    setCookie(hidePopoutPlayerDialogCookie.name, frequency);
    dispatch({ type: OPEN_POPOUT_PLAYER_DIALOG });
  };
}

export function closePopOutPlayerDialog() {
  return { type: CLOSE_POPOUT_PLAYER_DIALOG };
}

export const OPEN_USER_REGISTRATION_DIALOG = 'OPEN_USER_REGISTRATION_DIALOG';
export const CLOSE_USER_REGISTRATION_DIALOG = 'CLOSE_USER_REGISTRATION_DIALOG';
export const REQUEST_USER_REGISTRATION_DIALOG_OPEN =
  'REQUEST_USER_REGISTRATION_DIALOG_OPEN';

export function openUserRegistrationDialog() {
  return { type: OPEN_USER_REGISTRATION_DIALOG };
}

export function closeUserRegistrationDialog() {
  return { type: CLOSE_USER_REGISTRATION_DIALOG };
}

export function requestUserRegistrationDialogOpen(view) {
  return { type: REQUEST_USER_REGISTRATION_DIALOG_OPEN, view };
}

export function createPendingRegistrationDialogOpenRequest(view) {
  return { type: pending(REQUEST_USER_REGISTRATION_DIALOG_OPEN), view };
}

export const OPEN_LINK_WITH_ALEXA_DIALOG = 'OPEN_LINK_WITH_ALEXA_DIALOG';
export const CLOSE_LINK_WITH_ALEXA_DIALOG = 'CLOSE_LINK_WITH_ALEXA_DIALOG';

export function openLinkWithAlexaDialog() {
  return { type: OPEN_LINK_WITH_ALEXA_DIALOG };
}

export function closeLinkWithAlexaDialog() {
  return { type: CLOSE_LINK_WITH_ALEXA_DIALOG };
}

export const OPEN_MESSAGE_OF_THE_DAY_DIALOG = 'OPEN_MESSAGE_OF_THE_DAY_DIALOG';
export const CLOSE_MESSAGE_OF_THE_DAY_DIALOG =
  'CLOSE_MESSAGE_OF_THE_DAY_DIALOG';

export function openMessageOfTheDayDialog() {
  return { type: OPEN_MESSAGE_OF_THE_DAY_DIALOG };
}

export function closeMessageOfTheDayDialog() {
  return { type: CLOSE_MESSAGE_OF_THE_DAY_DIALOG };
}

export const OPEN_INTEREST_SELECTOR_DIALOG = 'OPEN_INTEREST_SELECTOR_DIALOG';
export const CLOSE_INTEREST_SELECTOR_DIALOG = 'CLOSE_INTEREST_SELECTOR_DIALOG';

export function openInterestSelectorDialog(categoryId) {
  return {
    type: OPEN_INTEREST_SELECTOR_DIALOG,
    meta: { categoryId },
  };
}

export function closeInterestSelectorDialog(isUserInitiated = false) {
  return (dispatch) => {
    dispatch({ type: CLOSE_INTEREST_SELECTOR_DIALOG });

    if (isUserInitiated) {
      dispatch(logInterestSelectorAction());
    }
  };
}

export const OPEN_ERROR_DIALOG = 'OPEN_ERROR_DIALOG';
export const CLOSE_ERROR_DIALOG = 'CLOSE_ERROR_DIALOG';

export function openErrorDialog() {
  return { type: OPEN_ERROR_DIALOG };
}

export function closeErrorDialog() {
  return { type: CLOSE_ERROR_DIALOG };
}

export function fetchItemsAndOpenIntSelDialog({ categoryId }) {
  return async (dispatch, getState) => {
    let items = getSelectCategory(categoryId)(getState())?.containerItems || [];

    if (!items.length) {
      await dispatch(fetchCategory({ guideId: categoryId }));
    }

    items = getSelectCategory(categoryId)(getState())?.containerItems || [];

    if (!items.length) {
      return dispatch(openErrorDialog());
    }

    dispatch(openInterestSelectorDialog(categoryId));
  };
}

export const OPEN_SHARE_DIALOG = 'OPEN_SHARE_DIALOG';
export const CLOSE_SHARE_DIALOG = 'CLOSE_SHARE_DIALOG';

export function openShareDialog(guideItem) {
  return (dispatch) => {
    dispatch(sharingFromProfile(guideItem));

    return dispatch({
      type: OPEN_SHARE_DIALOG,
      guideItem,
    });
  };
}

export function closeShareDialog() {
  return { type: CLOSE_SHARE_DIALOG };
}

export const UPDATE_VIDEO_DIALOG = 'UPDATE_VIDEO_DIALOG';
export function showVideoAdPlayer() {
  return {
    type: UPDATE_VIDEO_DIALOG,
    meta: {
      isOpen: true,
    },
  };
}

export function hideVideoAdPlayer() {
  return {
    type: UPDATE_VIDEO_DIALOG,
    meta: {
      isOpen: false,
      learnMoreUrl: null,
    },
  };
}

export function updateVideoAdFormat(videoWidth, videoHeight) {
  return {
    type: UPDATE_VIDEO_DIALOG,
    meta: {
      videoWidth,
      videoHeight,
    },
  };
}

export function setLearnMoreUrl(learnMoreUrl) {
  return {
    type: UPDATE_VIDEO_DIALOG,
    meta: {
      learnMoreUrl,
    },
  };
}
