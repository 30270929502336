import get from 'lodash/get';
import { BROWSIES_GUIDE_ID } from '../constants/categories';
import { selectIsFord } from '../selectors/app';
import getStoreGuideId from '../utils/guideItem/getStoreGuideId';
import { types } from '../utils/guideItemTypes';
import parseSort from '../utils/queryString/parseSort';
import mapCategoryResponse from './data/mapCategoryResponse';

export const FETCH_CATEGORY = 'FETCH_CATEGORY';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';
export const SET_BRAZE_CATEGORY_CARDS = 'SET_BRAZE_CATEGORY_CARDS';

export function fetchCategoryEffect(
  storeGuideId,
  { guideId, attributes, itemToken = '' },
) {
  return (dispatch, getState) => {
    const isFord = selectIsFord(getState());
    const transform = (resp) => ({
      content: mapCategoryResponse(resp.data, guideId, isFord),
    });

    const queryParams = {
      ...parseSort(attributes),
      itemToken,
    };

    return dispatch({
      type: FETCH_CATEGORY,
      api: {
        endpoint: ['browse', 'getItems'],
        args: [guideId, queryParams],
        transform,
      },
      meta: {
        contentId: storeGuideId,
      },
    });
  };
}

export function fetchCategoryWithContextEffect(
  type,
  guideId,
  contextGuideId,
  isNPEnabled,
) {
  return (dispatch, getState) => {
    const isFord = selectIsFord(getState());
    const transform = (resp) => ({
      content: mapCategoryResponse(resp.data, guideId, isFord),
    });

    return dispatch({
      type,
      api: {
        endpoint: ['browse', 'getItemsWithContext'],
        args: [guideId, contextGuideId],
        transform,
      },
      meta: {
        contentId: guideId,
        isNPEnabled,
      },
    });
  };
}

export function customBrowseItemsTransform(resp, guideId) {
  const containerItems = get(resp, 'data.items[0].children', []);
  return {
    content: {
      guideId,
      type: types.container,
      containerItems,
    },
  };
}

// this is different from the regular browse items call
// to be used for categories which return landing carousel type response
export function getCustomBrowseItemsByGuideId(guideId, type) {
  const transform = (resp) => customBrowseItemsTransform(resp, guideId);

  return {
    type,
    api: {
      endpoint: ['browse', 'getItems'],
      args: [guideId],
      transform,
    },
    meta: {
      contentId: guideId,
    },
  };
}

export function fetchCategory(guideContext) {
  const storeGuideId = getStoreGuideId(
    guideContext.guideId,
    guideContext.attributes,
  );
  return fetchCategoryEffect(storeGuideId, guideContext);
}

export function clearCategory(guideId) {
  return {
    type: CLEAR_CATEGORY,
    guideId,
  };
}

export function setBrazeCategoryCards(guideId, brazeCards) {
  return {
    type: SET_BRAZE_CATEGORY_CARDS,
    meta: {
      guideId,
      brazeCards,
    },
  };
}

export function fetchBrowsies() {
  return fetchCategory({ guideId: BROWSIES_GUIDE_ID });
}
