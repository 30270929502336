import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { selectSubscriptionProviderName } from '../../selectors/me';
import subscribedThroughWeb from '../../utils/subscription/subscribedThroughWeb';
import { getSubscription } from '../subscription';

export default function fetchSubscriptionIfNeeded({ store }) {
  const { me, subscription } = store.getState();
  const providerName = selectSubscriptionProviderName({ me });
  const isWebSubscription = subscribedThroughWeb(providerName);

  if (isWebSubscription && isEmpty(get(subscription, 'current'))) {
    return getSubscription();
  }

  return null;
}
