import { types } from 'src/common/utils/guideItemTypes';
import { BROWSIES_GUIDE_ID } from '../../constants/categories';
import { addReportingMetadata } from '../utils/addReportingMetadata';

function transformBrowsies(items) {
  const containerItems = items?.[0]?.children?.map(
    ({ accessibilityTitle, decorationTitle } = {}) => ({
      title: accessibilityTitle,
      path: decorationTitle,
    }),
  );

  return {
    containerItems,
    paths: Object.fromEntries(
      containerItems?.map(({ path } = {}) => [path, true]) || [],
    ),
  };
}

export default function mapCategoryResponse(data, guideId, isFord) {
  let { header, items, metadata } = data || {};
  const metadataProperties = metadata?.properties || {};

  if (guideId === BROWSIES_GUIDE_ID) {
    return transformBrowsies(items);
  }

  items?.forEach(addReportingMetadata(metadataProperties.seoInfo));

  if (isFord) {
    items = items?.map((item) => ({
      ...item,
      // items with a rowCount should show all children, otherwise only 5 items would be spread across N rows
      children: item?.presentation?.rowCount
        ? item?.children
        : item?.children?.slice(0, 5),
    }));
  }

  const transformedData = {
    guideId,
    type: types.category,
    title: header?.title || metadataProperties.guideInfo?.name || '',
    isTitleHidden: !header?.title, // title should be hidden if not explicitly set on header
    imageUrl: header?.imageUrl || '',
    containerItems: items,
    metadata,
  };

  return transformedData;
}
