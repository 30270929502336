import get from 'lodash/get';

export default function mapAuthResponse(response) {
  const userData = get(response, 'data.userData', {});
  const subscriptionStatus = get(
    userData,
    'subscription.subscriptionStatus',
    null,
  )?.toLowerCase();
  const subscriptionProviderName = get(
    userData,
    'subscription.subscriptionProviderName',
    null,
  );

  return {
    ...response.data, // tokens, etc
    userData: {
      id: userData.userName,
      image: userData.image,
      title: userData.displayName,
      subtitle: userData.userName,
      userName: userData.userName,
      email: userData.email,
      subscriptionStatus,
      subscriptionProviderName,
    },
  };
}

/*
example response info
{
  "userData": {
    "accountId": "u207200",
    "userName": "33d3289d",
    "sessionId": "ab633162-8c88-4a44-8ec7-5dfcd820ecea",
    "firstName": "Nadine",
    "lastName": "Kinnen",
    "email": "33d3289d@facebook.ext.radiotime.com",
    "gender": "F",
    "birthday": "1970-01-01T00:00:00",
    "displayName": "Nadine Kinnen",
    "subscription": {
      "subscriptionStatus": "Active",
      "subscriptionExpiresOn": "2020-02-08T21:57:06",
      "subscriptionProviderId": 11,
      "subscriptionProviderName": "recurly",
      "subscriptionKey": "4a3db18e39465f16cea47641bcad3da3"
    },
    "image": "https://graph.facebook.com/v2.6/872998053432/picture?width=300&height=300"
  }
}

*/
