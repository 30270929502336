export function addReportingMetadata(seoInfo) {
  return (containerItem) => {
    const {
      containerId,
      index,
      presentation,
      guideId: containerGuideId,
      title,
      children,
    } = containerItem;

    children?.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.reporting = {
        page: {
          guideId: seoInfo?.guideId,
          aliasName: seoInfo?.alias,
        },
        container: {
          id: containerId || containerGuideId,
          type: presentation?.layout,
          name: title,
          // Note: The platform returns the index starting from 1 and for the reporting we need to start from 0.
          position: index ? index - 1 : 0,
        },
      };
    });
  };
}
