export const SHOW_AVAILABLE_DESKTOP_UPDATE_BANNER =
  'SHOW_AVAILABLE_DESKTOP_UPDATE_BANNER';

export function showAvailableDesktopUpdateBanner(
  showManualUpdateBanner = false,
) {
  return {
    type: SHOW_AVAILABLE_DESKTOP_UPDATE_BANNER,
    showManualUpdateBanner,
  };
}
